import { Outlet } from "react-router-dom";
import HeaderStyle from "../components/Headerstyle/HeaderStyle";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { app_name } from "../config/app";

function MasterLayout() {
  const backToTop = document.getElementById("back-to-top");
  const { pathname } = useLocation();
  if (backToTop !== null && backToTop !== undefined) {
    document
      .getElementById("back-to-top")
      ?.classList?.add("animated", "fadeOut");
    window.addEventListener("scroll", (e) => {
      if (document.documentElement.scrollTop > 50) {
        document.getElementById("back-to-top")?.classList.remove("fadeOut");
        document.getElementById("back-to-top")?.classList.add("fadeIn");
      } else {
        document.getElementById("back-to-top")?.classList.remove("fadeIn");
        document.getElementById("back-to-top")?.classList.add("fadeOut");
      }
    });
    // scroll body to 0px on click
    document.querySelector("#top")?.addEventListener("click", (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }
  const checkIfPath =
    pathname === "/recover-pswd" ||
    pathname === "/sign-in" ||
    pathname === "/sign-up"
      ? true
      : false;

  const location = useLocation();

  const pathName = location.pathname.split("/")[1];

  const pathNameUpper =
    pathName?.replace(/-/g, " ")?.replace(/\b\w/g, (l) => l.toUpperCase()) ||
    "";
  document.title = `${app_name} ${pathNameUpper ? `| ${pathNameUpper}` : ""}`;

  return (
    <>
      <div id="back-to-top">
        <Link className="top" to="#" id="top">
          {" "}
          <i className="fa fa-angle-up"></i>{" "}
        </Link>
      </div>
      <div className="wraper">
        {!checkIfPath && <HeaderStyle />}

        <div className="content-page" id="content-page">
          <Outlet />
        </div>
      </div>

      {!checkIfPath && <Footer />}
    </>
  );
}

export default MasterLayout;
