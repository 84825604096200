import React from "react";
import Styles from "./MovieDetailsBanner.module.css";
const IframePlayer = ({ stream_url = "" }) => {
  return (
    <iframe
      width="100%"
      className={Styles.embed_responsive_video}
      // height={isMobile() ? "400px" : window.innerHeight - 300}
      src={stream_url}
      title="Youtube"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
};

export default IframePlayer;
