import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import parallax4 from '../../assets/images/parallax/parallax-logo.png'
// import parallax3 from '../../assets/images/parallax/p1.jpg'
// import StarRating from "../StarRating/StarRating";
import { durationFormat, handleEvent } from "../../utils/utils";

const ParallaxSection = ({ parallaxContent }) => {
  const {
    background,
    content: {
      id,
      // ratings,
      age_restriction,
      duration,
      description,
      //   thumbnail,
      title,
    },
  } = parallaxContent;
  return (
    <section
      id="parallex"
      className="parallax-window"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Container fluid className="h-100">
        <Row className="align-items-center justify-content-center h-100 parallaxt-details">
          <Col lg="4" className="r-mb-23">
            <div className="text-left">
              <Link to="#">
                <h1 className="slider-text big-title title text-uppercase">
                  {" "}
                  {title}
                </h1>
              </Link>
              {/* {ratings && (
                <div className="parallax-ratting d-flex align-items-center mt-3 mb-3">
                  <StarRating rating={ratings} />
                  <span className="text-white ml-3">{ratings} (lmdb)</span>
                </div>
              )} */}
              <div className="movie-time d-flex align-items-center mb-3 iq-ltr-direction">
                <div className="badge badge-secondary mr-3">
                  {age_restriction}+
                </div>
                <h6 className="text-white">{durationFormat(duration)}</h6>
              </div>
              <h4 className="iq-title mb-2">{title}</h4>
              <p>{description}</p>
              <div className="parallax-buttons">
                <Link
                  to={`/show-detail/movie/${id}`}
                  className="btn btn-hover"
                  onClick={() => {
                    handleEvent(
                      "Parallex",
                      "Clicked on Play Now Button",
                      title
                    );
                  }}
                >
                  Play Now
                </Link>
                {/* <Link to="/movie-details" className="btn btn-link">More details</Link> */}
              </div>
            </div>
          </Col>
          <Col lg="8">
            <div className="parallax-img">
              <Link
                to={`/show-detail/movie/${id}`}
                onClick={() => {
                  handleEvent("Parallex", "Clicked on Child Image", title);
                }}
              >
                <img
                  src={background}
                  className="img-fluid w-100"
                  alt="bailey"
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ParallaxSection;