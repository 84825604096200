// Home section
export const HERO_CONTENTS = "/v1/home/hero";
export const HOME_SLIDERS = "/v1/home/sliders";
export const MOVIES = "/v1/home/movies";
export const TV_SHOWS = "/v1/home/tv-shows";
export const TRENDING_NOW = "/v1/home/trending-now";
export const TOP_TEN = "/v1/home/top-ten";
export const RECOMMENDATION = "/v1/home/recommendation";
export const PARALLAX_CONTENT = "/v1/home/parallax-content";
export const CONTENT_DETAIL = "/v1/content-detail";
export const ADD_FAVOURITE = "/v1/add-favourite";

// User
export const FAQ = "/v1/faq";
export const SITE_DATA = "/v1/site-data";

// Auth
export const LOGIN = "/v1/login";
export const SEND_OTP = "/v1/send-otp";
export const OTP_LOGIN = "/v1/otp-login";
export const LOGOUT = "/v1/logout";
// Movie Show
export const MOVIE_SLIDERS = "/v1/movie/sliders";
export const MOVIES_POPULAR = "/v1/movie/popular";
export const MOVIES_LATEST = "/v1/movie/latest";
export const MOVIES_RECOMMENDATION = "/v1/movie/recommendation";
export const MOVIE_LIST = "/v1/movie";

// TV Show
export const TV_SHOW_SLIDERS = "/v1/tv-show/sliders";
export const TV_SHOW_POPULAR = "/v1/tv-show/popular";
export const TV_SHOW_LATEST = "/v1/tv-show/latest";
export const TV_SHOW_RECOMMENDATION = "/v1/tv-show/recommendation";
export const TV_SHOW_LIST = "/v1/tv-show";

// Bangla Natok
export const BANGLA_NATOK_SLIDERS = "/v1/bangla-natok/sliders";
export const BANGLA_NATOK_POPULAR = "/v1/bangla-natok/popular";
export const BANGLA_NATOK_LATEST = "/v1/bangla-natok/latest";
export const BANGLA_NATOK_RECOMMENDATION = "/v1/bangla-natok/recommendation";
export const BANGLA_NATOK_LIST = "/v1/bangla-natok";

// Music Video
export const MUSIC_VIDEO_SLIDERS = "/v1/music-video/sliders";
export const MUSIC_VIDEO_POPULAR = "/v1/music-video/popular";
export const MUSIC_VIDEO_LATEST = "/v1/music-video/latest";
export const MUSIC_VIDEO_RECOMMENDATION = "/v1/music-video/recommendation";
export const MUSIC_VIDEO_LIST = "/v1/music-video";

// Review
export const REVIEW = "/v1/review";

// Global search
export const GLOBAL_SEARCH = "/v1/search?search=";

// View Count
export const VIEW_COUNT = "/v1/view-count";

export const HERO_CONTENT_ALL = "/v1/hero-content";

export const TRENDING_NOW_ALL = "/v1/trending-now";

// Leaderboard
export const LEADER_BOARD = "/v1/games-leader-board";

// Games
export const GAMES_LIST = "/v1/games-list";
export const GAMES_URL = "/v1/games-url?id=";
export const GAMES_STATUS = "/v1/games-status";
