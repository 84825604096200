/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import StarRating from "../StarRating/StarRating";
// import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { getReleaseDate, handleEvent } from "utils/utils";
import { useAddToFavouriteMutation } from "features/home/movies/moviesApi";
import { useDispatch } from "react-redux";
import { isReactModal } from "features/videoPlayerSlice/modalVideoPlayerSlice";
import TrailerPlayer from "../VideoPlayer/TrailerPlayer/TrailerPlayer";
import TvSeriesModal from "../VideoPlayer/TvSeries/TvSeriesModal";

const Banner = ({ data }) => {
  const {
    id,
    content_id,
    stream_id,
    thumbnail,
    title,
    trailer,
    release_date,
    // ratings,
    description,
    tag,
    seasons,
    user_favourite,
    total_favourite,
  } = data || {};
  // Get last episode of last season
  const [addToFavourite, { data: favData }] = useAddToFavouriteMutation();
  const [currentUserFavorite, setCurrentUserFavorite] =
    React.useState(user_favourite);
  const [currentTotalFavourite, setCurrentTotalFavourite] =
    React.useState(total_favourite);
  const [modalShow, setModalShow] = React.useState(false);
  const [trailerOpen, setTrailerOpen] = React.useState(false);
  const [tvSeriesData, setTvSeriesData] = React.useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    if (favData?.code === 200) {
      setCurrentUserFavorite(favData?.data?.user_favourite);
      setCurrentTotalFavourite(favData?.data?.total_favourite);
    }
  }, [favData]);

  const handleAddToFavourite = (content_id, uuid) => {
    addToFavourite({ content_id, uuid });
  };
  const getLatestEpisode = (seasons) => {
    let episode =
      seasons[seasons.length - 1]?.episodes[
        seasons[seasons.length - 1]?.episodes.length - 1
      ];
    return {
      stream_url: episode?.stream_url,
      thumbnail: episode?.cover_image,
      stream_id: episode?.stream_id,
      thumbnail_sprite: episode?.thumbnail_sprite,
      duration: episode?.duration,
    };
  };

  return (
    <div
      className="banner-wrapper overlay-wrapper iq-main-slider"
      style={{
        backgroundImage: `url(${thumbnail})`,
      }}
    >
      {/* <VideoPlayer setToggler={setToggler} trailer={trailer} stream_url={stream_url} thumbnail={thumbnail} /> */}
      <div className="banner-caption">
        <div className="movie-detail">
          <div className="row">
            <div className="col-lg-12">
              <div className="trending-info p-0">
                <h1 className="trending-text big-title text-uppercase mt-0">
                  {title}
                </h1>
                {/* <div
                  className="slider-ratting d-flex align-items-center"
                  data-animation-in="fadeInLeft"
                >
                  <StarRating rating={ratings} />
                  <span className="text-white ml-3">{ratings} 
                  (imdb)
                  </span>
                </div> */}
                <ul className="p-0 mt-2 list-inline d-flex flex-wrap movie-content">
                  <li className="trending-list">
                    <a className="text-primary title" href="tags/action.html">
                      Action
                    </a>
                  </li>
                  <li className="trending-list">
                    <a
                      className="text-primary title"
                      href="tags/adventure.html"
                    >
                      Adventure
                    </a>
                  </li>
                  <li className="trending-list">
                    <a className="text-primary title" href="tags/drama.html">
                      Drama
                    </a>
                  </li>
                </ul>
                <div className="d-flex flex-wrap align-items-center text-white text-detail sesson-date">
                  {seasons?.length > 0 ? (
                    <span className="">{seasons?.length} Seasons </span>
                  ) : null}

                  <span className="trending-year">
                    {getReleaseDate(release_date)}
                  </span>
                </div>
                <div className="trending-dec">
                  <p className="m-0">{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <Link
            to="#"
            onClick={() => {
              handleEvent("Show Details", "Clicked on Watch latest Episode");
              setTvSeriesData(getLatestEpisode(seasons));
              setModalShow((prev) => !prev);
            }}
            className="d-flex align-items-center"
          >
            <div className="play-button">
              <i className="ri-play-fill"></i>
            </div>
            <h4 className="w-name text-white font-weight-700">
              Watch latest Episode
            </h4>
          </Link>
        </div>
        <div className="row">
          <div className="col-12 mt-auto mb-auto">
            <ul className="list-inline p-0 mb-3 share-icons music-play-lists">
              <li>
                <span
                  style={{
                    background: currentUserFavorite ? "#e50914" : "#fff",
                  }}
                  className={` count_bg`}
                  onClick={() => handleAddToFavourite(id, content_id)}
                >
                  <i
                    className={`ri-heart-fill`}
                    style={{
                      color: currentUserFavorite ? "#fff" : "#e50914",
                    }}
                  ></i>
                </span>
                {currentTotalFavourite ? (
                  <span className={`count-box`}>{currentTotalFavourite}+</span>
                ) : null}
              </li>
            </ul>
            {tag && (
              <ul className="p-0 list-inline d-flex flex-wrap align-items-center movie-content movie-space-action flex-wrap iq_tag-list">
                <li className="text-primary text-lable">
                  <i
                    className="fa fa-tags font-Weight-900"
                    aria-hidden="true"
                  ></i>
                  TAGS:
                </li>
                <li> {tag}</li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="trailor-video  text-sm-right p-3  col-md-3 col-12">
        <Link
          to="#"
          style={{
            cursor: trailer ? "pointer" : "context-menu",
          }}
          onClick={() => {
            handleEvent(
              "Show Details",
              "Clicked on Trailer Link Button",
              title
            );
            setTrailerOpen((prev) => !prev);
            dispatch(isReactModal());
          }}
          className="video-open playbtn block-images position-relative playbtn_thumbnail "
        >
          <img
            width="1920"
            height="1080"
            src={thumbnail}
            className="attachment-medium-large size-medium-large wp-post-image"
            alt=""
            loading="lazy"
          />
          {trailer && (
            <span className="content btn btn-transparant iq-button">
              <i className=" mr-2"></i>
              <span>Trailer Link</span>
            </span>
          )}
        </Link>
      </div>
      {modalShow && (
        <TvSeriesModal
          {...tvSeriesData}
          open={modalShow}
          setOpen={setModalShow}
        />
      )}
      {trailerOpen && (
        <TrailerPlayer
          trailer={trailer}
          thumbnail={thumbnail}
          stream_id={stream_id}
          isOpen={trailerOpen}
          setOpen={setTrailerOpen}
        />
      )}
    </div>
  );
};

export default Banner;
