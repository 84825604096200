import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const auth = useSelector((state) => state.auth);
    
    return (
        (auth?.accessToken && auth?.user) ?
            <Outlet />
            :
            <Navigate to="/sign-in" replace />
    )
}

export default ProtectedRoute;
