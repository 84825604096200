import { handleAllError } from "../../../utils/utils";
import { apiSlice } from "../../api/apiSlice";

export const parallaxContent = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        parallaxContents: builder.query({
            query: () => ({
                url: "/v1/home/parallax-content",
            }),

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    handleAllError(data);
                } catch (err) {
                    // do nothing
                }
            },
        }),
    }),
});

export const { useParallaxContentsQuery } = parallaxContent;