import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { durationFormat, handleEvent } from "../../utils/utils";
// import StarRating from "../StarRating/StarRating";
SwiperCore.use([Navigation]);

const TopTen = ({ topTenMovies, viewAll, id = "topTenMovies" }) => {
  return (
    <section className="py-4 s-margin">
      {topTenMovies?.length > 0 && (
        <Container fluid>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="main-title">Top 10 in Bangladesh</h4>
                {viewAll && (
                  <Link
                    className="iq-view-all"
                    to={"/view-all"}
                    onClick={() => {
                      handleEvent(
                        "Top 10 in Bangladesh",
                        "Clcked on View All Button",
                        "View All"
                      );
                    }}
                  >
                    View All
                  </Link>
                )}
              </div>
              <div id="favorites-contens" className="favourite-slider">
                <Swiper
                  spaceBetween={20}
                  navigation={{
                    nextEl: `#prev1-${id}`,
                    prevEl: `#next1-${id}`,
                  }}
                  loop={true}
                  breakpoints={{
                    320: { slidesPerView: 2 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                  }}
                  className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                >
                  <div
                    id={`next1-${id}`}
                    className="swiper-button swiper-button-prev"
                  >
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div
                    id={`prev1-${id}`}
                    className="swiper-button swiper-button-next"
                  >
                    <i className="fa fa-chevron-right"></i>
                  </div>
                  {topTenMovies?.map(
                    ({ id, title, ratings, cover_image, duration }, index) => (
                      <SwiperSlide className="slide-item" key={index}>
                        <div className="item block-images">
                          <img src={cover_image} alt="" />
                          <div>{index + 1}</div>
                        </div>
                        <div className="block-description">
                          <h6 className="iq-title">
                            <Link
                              to={`/show-detail/movie/${id}`}
                              onClick={() => {
                                handleEvent(
                                  "Top 10 in Bangladesh",
                                  "Clicked on Movie Title",
                                  title
                                );
                              }}
                            >
                              {title}
                            </Link>
                          </h6>
                          <div className="movie-time d-flex align-items-center my-2">
                            <span className="text-white">
                              {durationFormat(duration)}
                            </span>
                          </div>
                          {/* <div className="slider-ratting d-flex align-items-center mr-4 mt-2 mt-md-0">
                            <StarRating rating={ratings} />
                            <span className="text-white ml-2">{ratings}</span>
                          </div> */}
                          <div className="hover-buttons">
                            <Link
                              to={`/show-detail/movie/${id}`}
                              role="button"
                              className="btn btn-hover"
                              onClick={() => {
                                handleEvent(
                                  "Top 10 in Bangladesh",
                                  "Clicked on Play Now Button",
                                  title
                                );
                              }}
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};


export default TopTen;