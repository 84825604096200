import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { useSliderQuery } from "features/slider/sliderApi";
import {
  MOVIES_LATEST,
  MOVIES_POPULAR,
  MOVIES_RECOMMENDATION,
  MOVIE_SLIDERS,
} from "service/apiEndPoints";
import { durationFormat, getReleaseDate, handleEvent } from "utils/utils";
import CustomLoader from "ui/Loader/CustomLoader";
import TrailerPlayer from "components/VideoPlayer/TrailerPlayer/TrailerPlayer";
import Movies from "components/Movies/Movies";
import DataNotFound from "ui/DataNotFound/DataNotFound";
import "swiper/swiper-bundle.css";
import CompleteRegistrationModal from "views/login/CompleteRegistrationModal";
import { useSelector } from "react-redux";
// import StarRating from "components/StarRating/StarRating";
// import { ModalVideoReact } from 'components/ModalVideo/ModalVideoReact';

SwiperCore.use([Navigation, Autoplay]);

const MovieCategory = () => {
  const { data, isLoading } = useSliderQuery(MOVIE_SLIDERS);
  const authUser = useSelector((state) => state.auth?.user);
  const [videoUrl, setVideoUrl] = React.useState("");
  const [thumbnail, setThumbnail] = React.useState("");
  const [trailerOpen, setTrailerOpen] = React.useState(false);
  const [registrationModal, setRegistrationModal] = React.useState(false);
  const playTrailer = (url, thumbnail) => {
    setVideoUrl(url);
    setThumbnail(thumbnail);
    setTrailerOpen((prev) => !prev);
  };

  // What to do render
  if (isLoading) return <CustomLoader />;

  return (
    <>
      {data?.data?.length > 0 ? (
        <section id="movieshow" className="banner-container iq-main-slider p-0">
          <div
            id="prev"
            className="swiper-button swiper-button-prev main-carousel-left-arrow"
          >
            <i className="ri-arrow-left-s-line arrow-icon"></i>
          </div>
          <div
            id="next"
            className="swiper-button swiper-button-next main-carousel-right-arrow"
          >
            <i className="ri-arrow-right-s-line arrow-icon"></i>
          </div>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={0}
            centeredSlides={true}
            grabCursor={true}
            navigation={{
              prevEl: "#prev",
              nextEl: "#next",
            }}
            loop={true}
            className="movie-category-slide"
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {data?.data?.map(
              (
                {
                  content_id,
                  content_data: {
                    title,
                    thumbnail,
                    duration,
                    release_date,
                    ratings,
                    description,
                    trailer,
                  },
                  image,
                },
                index
              ) => (
                <SwiperSlide key={index}>
                  <div className="shows-img">
                    <img src={image} className="w-100 img" alt="" />
                    <div className="shows-content h-100">
                      <div className="row align-items-center h-100">
                        <div className="col-lg-7 col-md-12">
                          <h1
                            className="slider-text big-title title text-uppercase"
                            data-animation-in="fadeInLeft"
                            data-delay-in="0.6"
                          >
                            {title}
                          </h1>
                          <div
                            className="flex-wrap align-items-center fadeInLeft animated"
                            data-animation-in="fadeInLeft"
                            style={{
                              opacity: 1,
                            }}
                          >
                            {/* <div className="slider-ratting d-flex align-items-center ">
                              <StarRating rating={ratings} />
                              <span className="text-white ml-3">
                                {ratings} (lmdb)
                              </span>
                            </div> */}
                            <div className="d-flex align-items-center movie-banner-time">
                              <span className="badge badge-secondary p-2">
                                PG
                              </span>
                              <span className="mx-2 mx-md-3">
                                <i className="ri-checkbox-blank-circle-fill"></i>
                              </span>
                              <span className="trending-time">
                                {durationFormat(duration)}
                              </span>
                              <span className="mx-2 mx-md-3">
                                <i className="ri-checkbox-blank-circle-fill"></i>
                              </span>
                              <span className="trending-year">
                                {getReleaseDate(release_date)}
                              </span>
                            </div>
                            <p
                              className="movie-banner-text"
                              data-animation-in="fadeInUp"
                              data-delay-in="1.2"
                            >
                              {description}
                            </p>
                          </div>
                          <div
                            className="d-flex align-items-center r-mb-23"
                            data-animation-in="fadeInUp"
                            data-delay-in="1.2"
                          >
                            <Link
                              to={
                                authUser?.status === 3
                                  ? "#"
                                  : `/show-detail/movie/${content_id}`
                              }
                              className="btn btn-hover iq-button"
                              onClick={() => {
                                handleEvent(
                                  "Movie Category",
                                  "Clicked on Play Now Button",
                                  title
                                );

                                if (authUser?.status !== 3) {
                                  return;
                                }
                                setRegistrationModal(true);
                              }}
                            >
                              <i
                                className="fa fa-play mr-2"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                        {trailer && (
                          <div className="col-lg-5 col-md-12 trailor-video iq-slider d-none d-lg-block">
                            <Link
                              to="#"
                              onClick={() => {
                                handleEvent(
                                  "Movie Category",
                                  "Clicked on Watch Trailer Button",
                                  title
                                );
                                if (authUser?.status !== 3) {
                                  playTrailer(trailer, thumbnail);
                                  return;
                                }
                                setRegistrationModal(true);
                              }}
                              className="video-open playbtn"
                            >
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                width="80px"
                                height="80px"
                                viewBox="0 0 213.7 213.7"
                                enableBackground="new 0 0 213.7 213.7"
                                xmlSpace="preserve"
                              >
                                <polygon
                                  className="triangle"
                                  fill="none"
                                  strokeWidth="7"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  points="73.5,62.5 148.5,105.8 73.5,149.1 "
                                />
                                <circle
                                  className="circle"
                                  fill="none"
                                  strokeWidth="7"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  cx="106.8"
                                  cy="106.8"
                                  r="103.3"
                                />
                              </svg>
                              <span className="w-trailor">Watch Trailer</span>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
          {/* <Genres /> */}
        </section>
      ) : (
        <DataNotFound type="slider" />
      )}

      <div
        className="main-content"
        style={{
          paddingTop: data?.data?.length > 0 ? "40px" : "0px",
        }}
      >
        <Movies
          label="Popular Movies"
          id="iq-favorites"
          endpoint={MOVIES_POPULAR}
          viewAll
          path="/view-all/movie"
        />
        <Movies
          label="Specials & Latest Movies"
          id="iq-upcoming-movie"
          endpoint={MOVIES_LATEST}
        />
        <Movies
          label="Movies Recommended For You"
          id="iq-suggestede"
          endpoint={MOVIES_RECOMMENDATION}
        />
      </div>
      {trailerOpen && (
        <TrailerPlayer
          trailer={videoUrl}
          thumbnail={thumbnail}
          isOpen={trailerOpen}
          setOpen={setTrailerOpen}
        />
      )}

      {registrationModal && (
        <CompleteRegistrationModal
          show={registrationModal}
          onHide={() => setRegistrationModal(false)}
        />
      )}
    </>
  );
};
export default MovieCategory;
