import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import icon from "assets/video/trailer.mp4";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import { useTopTenMoviesQuery } from "features/home/topTen/topTenApi";
import { useParallaxContentsQuery } from "features/home/parallaxContent/parallaxContentApi";
import {
  HERO_CONTENTS,
  MOVIES,
  PARALLAX_CONTENT,
  RECOMMENDATION,
  TRENDING_NOW,
  TV_SHOWS,
  GAMES_LIST,
  BANGLA_NATOK_POPULAR,
} from "service/apiEndPoints";
import { useGetMoviesQuery } from "features/home/movies/moviesApi";
import ParallaxSection from "components/Home/ParallaxSection";
import TrendingSection from "components/Home/TrendingSection";
import TopTen from "components/TopTen/TopTen";
import MainCarousel from "components/Home/MainCarousel";
import Movies from "components/Movies/Movies";
import GameList from "components/Games/GameList";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const Homepage = () => {
  const [toggler2] = useState(false);
  const [toggler3] = useState(false);
  const { data: parallaxContent } = useParallaxContentsQuery();
  const { data: topTenMovies } = useTopTenMoviesQuery();
  const { data: trendingNow } = useGetMoviesQuery(TRENDING_NOW);

  return (
    <>
      <FsLightbox
        toggler={toggler2}
        sources={[
          <iframe
            src={icon}
            title=" "
            width="500px"
            height="200px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
      <FsLightbox
        toggler={toggler3}
        sources={[
          <iframe
            src={icon}
            title=" "
            width="500px"
            height="200px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
      {/* {authUser?.status === 3 ? <GamesMainSlider /> : <MainCarousel />} */}

      <MainCarousel />
      <div className="main-content">
        <GameList
          label="Games"
          id="iq-games"
          endpoint={GAMES_LIST}
          type="natok"
          path="/game-details"
        />
        <Movies
          label="Movies"
          id="iq-upcoming-movies"
          viewAll
          playPath="/show-detail"
          endpoint={MOVIES}
          path={`/view-all/movie`}
        />
        <Movies
          id="iq-upcoming-tv-series"
          label="TV Series"
          viewAll
          endpoint={TV_SHOWS}
          path={`/view-all/tv-series`}
          type="series"
        />

        <Movies
          label="Bangla Natok"
          id="iq-favorites"
          endpoint={BANGLA_NATOK_POPULAR}
          type="natok"
          viewAll
          path="/view-all/bangla-natok"
        />
        {/* <Movies id='iq-upcoming-movie' label='Trending' viewAll endpoint={TRENDING_NOW} /> */}
        {trendingNow?.data?.length ? (
          <TrendingSection
            data={trendingNow?.data}
            viewAll
            path={`/view-all/trending-now`}
          />
        ) : null}
        {topTenMovies?.data?.length ? (
          <TopTen topTenMovies={topTenMovies?.data} />
        ) : null}
        <Movies
          id="iq-tvthrillers-hero"
          cls="s-margin mb-5"
          label="Hero Contents"
          endpoint={HERO_CONTENTS}
          viewAll
          path={`/view-all/hero-contents`}
        />
        {parallaxContent?.data && (
          <ParallaxSection
            parallaxContent={parallaxContent?.data}
            endpoint={PARALLAX_CONTENT}
          />
        )}

        <Movies
          id="iq-tvthrillers"
          cls="s-margin mb-5"
          label="Recommended For You"
          endpoint={RECOMMENDATION}
        />
      </div>
    </>
  );
};

export default Homepage;
