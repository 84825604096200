import { handleAllError } from "../../../utils/utils";
import { apiSlice } from "../../api/apiSlice";

export const topTen = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        topTenMovies: builder.query({
            query: () => "/v1/home/trending-now",

            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    handleAllError(data);
                } catch (err) {
                    // do nothing
                }
            },
        }),
    }),
});

export const { useTopTenMoviesQuery } = topTen;