import statusEnum from "../../../enums/statusEnum";
import {
  HERO_CONTENTS,
  MOVIES,
  MUSIC_VIDEO_LATEST,
  RECOMMENDATION,
  TV_SHOWS,
  MUSIC_VIDEO_POPULAR,
  MUSIC_VIDEO_RECOMMENDATION,
  TV_SHOW_POPULAR,
  TV_SHOW_LATEST,
  TV_SHOW_RECOMMENDATION,
  MOVIES_POPULAR,
  MOVIES_LATEST,
  MOVIES_RECOMMENDATION,
  BANGLA_NATOK_POPULAR,
  BANGLA_NATOK_LATEST,
  BANGLA_NATOK_RECOMMENDATION,
} from "../../../service/apiEndPoints";
import { errorToast, handleAllError, successToast } from "../../../utils/utils";
import { apiSlice } from "../../api/apiSlice";

export const moviesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMovies: builder.query({
      query: (endpoint) => endpoint,
      async onQueryStarted(arg, { queryFulfilled, dispatch, getState }) {
        try {
          const { data } = await queryFulfilled;
          //   dispatch(
          //     moviesApi.util.updateQueryData("getMovies", arg, (draft) => {
          //         // Append new data
          //         const existingContent = JSON.parse(JSON.stringify(draft));
          //         console.log("existingContent", existingContent);
          //         console.log("data", data);
          //         const previousData = existingContent?.data;
          //         const newData = data?.data;
          //         const paginator = data?.paginator;
          //         const updatedData = [...previousData, ...newData];
          //         const updatedPaginator = paginator;
          //         console.log("updatedData", updatedData);
          //         console.log("updatedPaginator", updatedPaginator);
          //         console.log({
          //             ...existingContent,
          //             data: updatedData,
          //             paginator: updatedPaginator,
          //           })
          //         return {
          //           ...existingContent,
          //           data: updatedData,
          //           paginator: updatedPaginator,
          //         };
          //     })
          //   );
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),

    addToFavourite: builder.mutation({
      query: ({ content_id }) => ({
        url: "/v1/add-favourite",
        method: "POST",
        body: { content_id },
      }),

      async onQueryStarted({ uuid }, { queryFulfilled, dispatch }) {
        const endPointList = [
          MOVIES,
          TV_SHOWS,
          HERO_CONTENTS,
          RECOMMENDATION,
          MUSIC_VIDEO_POPULAR,
          MUSIC_VIDEO_LATEST,
          MUSIC_VIDEO_RECOMMENDATION,
          TV_SHOW_POPULAR,
          TV_SHOW_LATEST,
          TV_SHOW_RECOMMENDATION,
          MOVIES_POPULAR,
          MOVIES_LATEST,
          MOVIES_RECOMMENDATION,
          BANGLA_NATOK_POPULAR,
          BANGLA_NATOK_LATEST,
          BANGLA_NATOK_RECOMMENDATION,
        ];

        // Optimistic update

        // eslint-disable-next-line array-callback-return
        endPointList.map((endpoint) => {
          dispatch(
            moviesApi.util.updateQueryData("getMovies", endpoint, (draft) => {
              const existingContent = JSON.parse(JSON.stringify(draft));
              const findOutContent = existingContent?.data?.find(
                (item) => item?.content_id === uuid
              );
              if (findOutContent) {
                findOutContent.user_favourite = !findOutContent?.user_favourite;
                findOutContent.total_favourite = findOutContent?.user_favourite
                  ? findOutContent?.total_favourite + 1
                  : findOutContent?.total_favourite - 1;
              }
              return existingContent;
            })
          );
        });

        try {
          const { data } = await queryFulfilled;
          if (data?.code === statusEnum.SUCCESS) {
            successToast(data?.message[0]);
          } else {
            errorToast(data?.message[0]);
            // updateMovie();
          }
        } catch (err) {
          // updateMovie.undo();
        }
      },
    }),

    loadMoreGet: builder.query({
      query: (endpoint) => endpoint,
      async onQueryStarted({ endpoint }, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            moviesApi.util.updateQueryData("getMovies", endpoint, (draft) => {
              const existingContent = JSON.parse(JSON.stringify(draft));
              // insert new data
              console.log("existingContent", existingContent);
              console.log("data", data);

              return existingContent;
            })
          );
        } catch (err) {
          console.log("err", err);
        }
      },
    }),

    loadMore: builder.query({
      query: ({ page = "", endpoint = "" }) => {
        return endpoint;
      },

      async onQueryStarted({ endpoint, page }, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            moviesApi.util.updateQueryData(
              "getMovies",
              "/v1/bangla-natok",
              (draft) => {
                const existingContent = JSON.parse(JSON.stringify(draft));
                const newData = data?.data?.data;
                const previousData = existingContent?.data?.data;
                const updatedData = [...previousData, ...newData];
                const updatedPaginator = data?.data?.paginator;
                return {
                  ...existingContent,
                  data: {
                    ...existingContent?.data,
                    data: updatedData,
                    paginator: updatedPaginator,
                  },
                };
              }
            )
          );
        } catch (err) {
          console.log("err", err);
        }
      },
    }),
  }),
});

export const {
  useGetMoviesQuery,
  useAddToFavouriteMutation,
  useLoadMoreQuery,
} = moviesApi;
