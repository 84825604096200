import React from "react";
import logo from "assets/images/logo.png";
import { useLoginMutation } from "features/auth/authApi";
import { ThreeDotLoader } from "ui/ThreeDotLoader/ThreeDotLoader";
import { handleEvent, valid_mobile, warningToast } from "utils/utils";
import Otp from "components/OTP/Otp";

const Login = () => {
  const [msisdn, setMsisdn] = React.useState("");
  const [isOtp, setIsOtp] = React.useState(false);
  const [login, { isLoading }] = useLoginMutation();

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!valid_mobile(msisdn)) {
      warningToast("Please enter a valid phone number");
      return;
    }
    handleEvent("Login Form", "Clicked on Submit Button", "Submit");
    login({ msisdn: msisdn })
      .unwrap()
      .then((res) => {
        if (res?.code === 200) {
          setIsOtp(true);
        }
      });
  };

  return (
    <>
      {isOtp ? (
        <Otp msisdn={msisdn} isOtp={isOtp} setIsOtp={setIsOtp} login={login} />
      ) : (
        <section className="sign-in-page">
          <div className="container">
            <div className="row justify-content-center align-items-center height-self-center">
              <div className="col-lg-5 col-md-12 align-self-center sign_in_column">
                <div className="sign-user_card bg-white">
                  <div className="sign-in-page-data">
                    <div className="sign-in-from w-100 m-auto text-center">
                      <img
                        src={logo}
                        alt=""
                        width="100px"
                        className="mb-3 login-logo"
                      />
                      <p className="mb-0 text-black"> Welcome to </p>
                      <h3 className="mb-3 text-center logo-text text-uppercase text-secondary">
                        {" "}
                        Breakstation!{" "}
                      </h3>
                      <form className="mt-4" onSubmit={submitHandler}>
                        <div className="form-group">
                          <label htmlFor="Phone No." className="fw-normal mb-2">
                            {" "}
                            Enter Phone No.{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control mb-0 text-black"
                            id="exampleInputEmail1"
                            placeholder="Enter your phone no."
                            onChange={({ target }) => {
                              setMsisdn(target.value);
                            }}
                            autoComplete="off"
                            required
                            // maxLength={11}
                            pattern="[0-9]{11}"
                          />
                        </div>
                        <div className="sign-info">
                          <button
                            type="submit"
                            className="btn btn-hover"
                            disabled={isLoading}
                            style={{
                              cursor: isLoading ? "not-allowed" : "pointer",
                            }}
                          >
                            {isLoading ? (
                              <ThreeDotLoader
                                color="#fff"
                                height={20}
                                width={35}
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;
