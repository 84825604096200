import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userLoggedOut } from "../auth/authSlice";
import { errorToast } from "../../utils/utils";
import statusEnum from "../../enums/statusEnum";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const token = getState()?.auth?.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      // Get content type from endpoint

      if (endpoint !== "profileUpdate") {
        headers.set("Content-Type", "application/json");
      }
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    try {
      let result = await baseQuery(args, api, extraOptions);
      if (result?.data?.code === statusEnum.UN_AUTH) {
        api.dispatch(userLoggedOut());
        localStorage.clear();
      } else if (result?.error?.status === "FETCH_ERROR") {
        api.dispatch(userLoggedOut());
        localStorage.clear();
        return errorToast(result?.error?.error);
      } else if (result?.error?.status === "PARSING_ERROR") {
        return errorToast(result?.error?.error);
      }
      return result;
    } catch (error) {}
  },
  tagTypes: [],
  endpoints: (builder) => ({}),
});
