import React from "react";
import { Link } from "react-router-dom";
import { handleEvent } from "../../utils/utils";
import { useSelector } from "react-redux";

const MovieHeader = ({
  label = "",
  viewAll = false,
  path = "",
  setModalShow = () => { },
}) => {
  const authUser = useSelector((state) => state.auth?.user);
  return (
    <div className="d-flex align-items-center justify-content-between">
      <h4 className="main-title">{label}</h4>
      {viewAll && (
        <Link
          className="iq-view-all"
          to={authUser?.status === 3 ? "#" : path}
          onClick={() => {
            handleEvent(label, "Clicked on View ALL ", "View All");
            if (authUser?.status !== 3) {
              return;
            }
            setModalShow(true);
          }}
        >
          View All
        </Link>
      )}
    </div>
  );
};

export default MovieHeader;
