import {Link} from "react-router-dom";
import urls from "../enums/urls";
function Unauthorized(){
    return(
        <div className="not-found-wrap text-center">
            <h1 className="text-60">401</h1>
            <p className="text-36 subheading mb-3">Error!</p>
            <p className="mb-5 text-muted text-18">Sorry! You have no permission to perform this task.</p>
            <Link
                className="btn btn-lg btn-outline-primary btn-rounded" to={urls.DASHBOARD.url}>Go back to home</Link>
        </div>
    )
}

export default Unauthorized