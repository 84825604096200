import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import faqBg from "assets/images/faqBg.jpg";

const Breadcrumbs = ({ title = "" }) => {
  return (
    <div
      className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
      style={{ backgroundImage: `url(${faqBg})` }}
    >
      <Container fluid>
        <Row className="align-items-center">
          <Col sm="12">
            <nav
              aria-label="breadcrumb"
              className="text-center iq-breadcrumb-two"
            >
              <h2 className="title">{title}</h2>
              <Breadcrumb className="main-bg">
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
              </Breadcrumb>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Breadcrumbs;
