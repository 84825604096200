import { apiSlice } from "../api/apiSlice";
import { LEADER_BOARD } from "../../service/apiEndPoints";
import { handleAllError } from "../../utils/utils";

export const leaderBoardApi = apiSlice.injectEndpoints({
  tagTypes: ["LeaderBoard"],
  endpoints: (builder) => ({
    getLeaderBoard: builder.query({
      query: () => LEADER_BOARD,
      providesTags: ["LeaderBoard"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
  }),
});

export const { useGetLeaderBoardQuery } = leaderBoardApi;
