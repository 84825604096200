import React from "react";
import leaderBoardImage from "../../assets/images/leaderboard-bg-1.jpg";
import { useGetLeaderBoardQuery } from "../../features/leaderBoard/leaderBoardApi";
import { useSelector } from "react-redux";
import CustomLoader from "ui/Loader/CustomLoader";

const LeaderBoard = () => {
  const { data, isLoading } = useGetLeaderBoardQuery();
  const authUser = useSelector((state) => state.auth?.user);

  if (isLoading) return <CustomLoader />;

  return (
    <div className="main-content py-10">
      <div
        className="leaderboard mt-4"
        style={{
          backgroundImage: `url(${leaderBoardImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container-fluid pb-4">
          <div className="row">
            <div className="col-lg-6 m-auto">
              <h1 className="text-center mb-4">Leaderboard</h1>

              <div className="table table-responsive glass-card">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        Rank
                      </th>
                      <th scope="col">Name</th>
                      <th scope="col" className="text-center">
                        Points
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.data?.data?.map(
                      ({ name, image, total_point }, index) => {
                        const isCurrentUser =
                          index + 1 === data?.data?.user_current_position;
                        return (
                          <tr
                            key={index}
                            style={{
                              ...(index + 1 ===
                                data?.data?.user_current_position && {
                                backgroundColor: "#4648a8",
                              }),
                            }}
                          >
                            <td className="text-center">
                              <span
                                className={
                                  index < 3
                                    ? `rank-${index + 1} number`
                                    : "number"
                                }
                              >
                                {" "}
                                {index + 1}{" "}
                              </span>
                            </td>
                            <td className="name">
                              <img
                                src={image}
                                alt=""
                                width="35"
                                style={{
                                  // ...(index + 1 ===
                                  //   data?.data?.user_current_position && {
                                  //   border: "3px solid #fff",
                                  // }),
                                  height: "35px",
                                  borderRadius: "50%",
                                }}
                                className="mr-2 sm-28 rounded-circle"
                              />
                              {isCurrentUser ? authUser?.name : name}{" "}
                              {isCurrentUser ? "(You)" : ""}
                            </td>
                            <td className="text-center score">
                              {total_point}{" "}
                              {index === 0
                                ? "🏆"
                                : index === 1
                                ? "🌟"
                                : index === 2
                                ? "👏"
                                : index + 1 ===
                                  data?.data?.user_current_position
                                ? "😇"
                                : ""}
                            </td>
                          </tr>
                        );
                      }
                    )}
                    {data?.data?.user_current_position > 9 && (
                      <tr style={{ backgroundColor: "#4648a8" }}>
                        <td className="text-center">
                          <span className="number">
                            {" "}
                            {data?.data?.data?.length + 1}{" "}
                          </span>
                        </td>
                        <td className="name">
                          <img
                            src={authUser?.image}
                            alt=""
                            width="35"
                            className="mr-2 sm-28"
                            style={{
                              height: "35px",
                              borderRadius: "50%",
                            }}
                          />
                          {authUser?.name} (You)
                        </td>
                        <td className="text-center score">
                          {authUser?.total_point} 😇
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
