import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import SwiperCore, { Navigation } from "swiper";
import {
  useAddToFavouriteMutation,
  useGetMoviesQuery,
} from "features/home/movies/moviesApi";
import { NextButton, PreviousButton } from "ui/Buttons/Buttons";
import { SliderCard } from "components/Common/Card/Cards";
import CustomLoader from "ui/Loader/CustomLoader";
import CompleteRegistrationModal from "views/login/CompleteRegistrationModal";
import MovieHeader from "./MovieHeader";
import Movie from "./Movie";

import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation]);

const Movies = ({
  label = "",
  id = "iq-favorites",
  cls = "",
  viewAll,
  path = "/view-all",
  endpoint,
}) => {
  const { data, isLoading } = useGetMoviesQuery(endpoint);
  const [addToFavourite] = useAddToFavouriteMutation();
  const [modalShow, setModalShow] = React.useState(false);
  const authUser = useSelector((state) => state.auth?.user);
  const handleAddToFavourite = async (content_id, uuid) => {
    await addToFavourite({ content_id, uuid });
  };

  // What to do render
  if (isLoading) return <CustomLoader />;
  return (
    data?.data?.length > 0 && (
      <section id={id} className={cls}>
        <Container fluid>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <MovieHeader
                label={label}
                viewAll={viewAll && data?.data?.length > 0}
                path={path}
                setModalShow={setModalShow}
              />
              <SliderCard>
                <Swiper
                  spaceBetween={20}
                  navigation={{
                    nextEl: `#prev1-${id}`,
                    prevEl: `#next1-${id}`,
                  }}
                  loop={true}
                  breakpoints={{
                    320: { slidesPerView: 2 },
                    768: { slidesPerView: 3 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                  }}
                  className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                >
                  <PreviousButton id={id} />
                  <NextButton id={id} />

                  {data?.data?.map((item, index) => (
                    <SwiperSlide className="slide-item" key={index}>
                      <Movie
                        item={item}
                        authUser={authUser}
                        label={label}
                        setModalShow={setModalShow}
                        handleAddToFavourite={handleAddToFavourite}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SliderCard>
            </Col>
          </Row>
        </Container>
        {modalShow && (
          <CompleteRegistrationModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        )}
      </section>
    )
  );
};

export default Movies;
