import React from "react";
import { HOME_SLIDERS } from "service/apiEndPoints";
import { useSliderQuery } from "features/slider/sliderApi";
import CustomLoader from "ui/Loader/CustomLoader";
import SliderContent from "./SliderContent";
import DataNotFound from "ui/DataNotFound/DataNotFound";

const MainCarousel = () => {
  const { data, isLoading } = useSliderQuery(HOME_SLIDERS);

  // What to do render
  let content = null;
  if (isLoading) return <CustomLoader />;
  else if (data?.data?.length > 0) {
    content = <SliderContent data={data?.data} />;
  } else {
    content = <DataNotFound type="slider" />;
  }

  return content;
};


export default MainCarousel;