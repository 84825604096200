import Style from './StarRating.module.css';
export default function StarRating({ rating = 0 }) {
    return (
        <ul className="ratting-start p-0 m-0 list-inline text-primary d-flex align-items-center justify-content-left">
            {
                rating ? Array(parseInt(rating)).fill(0).map((d, key) => (
                    <li className={Style.star__rating__li} key={key}>
                        <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                )) : Array((parseInt(5))).fill(0).map((d, key) => (
                    <li className={Style.star__rating__li} key={key}>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </li>
                ))
            }
            {
                rating && (rating % 1 > .4) ?
                    <li className={Style.star__rating__li}>
                        <i className="fa fa-star-half" aria-hidden="true"></i>
                    </li> : null
            }
            {
                rating && Array((parseInt(5 - (rating - ((rating % 1 && rating % 1 < .5) ? 1 : 0))))).fill(0).map((d, key) => (
                    <li className={Style.star__rating__li} key={key}>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </li>
                ))
            }
        </ul>
    );
}