import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGameListQuery } from "features/games/gamesApi";
import { NextButton, PlayButton, PreviousButton } from "ui/Buttons/Buttons";
import { MediaCard, SliderCard } from "components/Common/Card/Cards";
import CustomLoader from "ui/Loader/CustomLoader";
import SwiperCore, { Navigation } from "swiper";
import MovieHeader from "../Movies/MovieHeader";
import CardTitle from "components/Common/Card/CardTitle";
import ImageBox from "components/Common/Card/ImageBox";
import ContentCardDescription from "components/Common/Card/ContentCardDescription";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation]);

const GameList = ({
  label = "",
  id = "iq-favorites",
  cls = "",
  viewAll,
  path = "/view-all",
}) => {
  const { data, isLoading } = useGameListQuery();
  // What to do render
  if (isLoading) return <CustomLoader />;
  return (
    data?.data?.length > 0 && (
      <section id={id} className={cls}>
        <Container fluid>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <MovieHeader
                label={label}
                viewAll={viewAll && data?.data?.length > 0}
                path={path}
              />
              <SliderCard>
                <Swiper
                  spaceBetween={20}
                  navigation={{
                    nextEl: `#prev1-${id}`,
                    prevEl: `#next1-${id}`,
                  }}
                  loop={true}
                  breakpoints={{
                    320: { slidesPerView: 2 },
                  }}
                  className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                >
                  <PreviousButton id={id} />
                  <NextButton id={id} />
                  {data?.data?.map(
                    ({ id, name, cover_image, is_upcoming }, index) => (
                      <SwiperSlide className="slide-item" key={index}>
                        <MediaCard>
                          <ImageBox img={cover_image} />
                          <ContentCardDescription>
                            <CardTitle
                              path={is_upcoming ? "#" : `/game-details/${id}`}
                              label={label}
                              name={`${name} ${
                                is_upcoming ? "(Coming Soon)" : ""
                              }`}
                              mb={2}
                              is_upcoming={is_upcoming}
                            />
                            <PlayButton
                              path={is_upcoming ? "#" : `/game-details/${id}`}
                              label={label}
                              name={name}
                              is_upcoming={is_upcoming}
                            />
                          </ContentCardDescription>
                        </MediaCard>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </SliderCard>
            </Col>
          </Row>
        </Container>
      </section>
    )
  );
};

export default GameList;
