import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchedList: []
};

const searchedSlice = createSlice({
    name: "searchedList",
    initialState,
    reducers: {
        searchedList: (state, action) => {
            state.searchedList = action.payload;
        }
    },
});

export const { searchedList } = searchedSlice.actions;
export default searchedSlice.reducer;