import React from "react";
import { durationFormat, handleEvent } from "../../utils/utils";
import { FavouriteButtonAndCount, PlayButton } from "ui/Buttons/Buttons";
import { MediaCard } from "components/Common/Card/Cards";
import ImageBox from "components/Common/Card/ImageBox";
import CardTitle from "components/Common/Card/CardTitle";
import ContentCardDescription from "components/Common/Card/ContentCardDescription";

const Movie = ({
  item,
  authUser,
  label,
  setModalShow,
  handleAddToFavourite,
}) => {
  const { id, content_id, title, cover_image, duration } = item;

  return (
    <MediaCard>
      <ImageBox img={cover_image} />
      <ContentCardDescription>
        <CardTitle
          path={authUser?.status === 3 ? "#" : `/show-detail/movie/${id}`}
          label={label}
          name={title}
          onClick={() => {
            handleEvent(label, "Clicked on Title", title);
            if (authUser?.status !== 3) {
              return;
            }
            setModalShow(true);
          }}
        />
        <div className="movie-time d-flex align-items-center my-2">
          <span className="text-white">{durationFormat(duration)}</span>
        </div>
        <PlayButton
          path={authUser?.status === 3 ? "#" : `/show-detail/movie/${id}`}
          label={label}
          name={title}
          onClick={() => {
            handleEvent(label, "Clicked on Play Now Button", title);
            if (authUser?.status !== 3) {
              return;
            }
            setModalShow(true);
          }}
        />
      </ContentCardDescription>

      <FavouriteButtonAndCount
        item={item}
        onClick={() => {
          handleEvent(label, "Clicked on Favourite Icon Button", title);
          authUser?.status === 3
            ? setModalShow(true)
            : handleAddToFavourite(id, content_id);
        }}
      />
    </MediaCard>
  );
};

export default Movie;
