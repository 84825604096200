import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useGetFAQQuery } from "features/faq/faqApi";
import Breadcrumbs from "ui/Breadcrumb/Breadcrumbs";

const FAQ = () => {
  const [faq, setfaq] = useState("1");
  const { data } = useGetFAQQuery();

  return (
    <>
      <Breadcrumbs title="FAQ" />
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-accordion iq-accordion-square">
                {data?.data?.map(({ question, answer }, index) => {
                  return (
                    <div
                      className={`iq-accordion-block  ${index + 1} ${
                        faq === `${index + 1}` ? "iq-active" : ""
                      }`}
                      onClick={() => {
                        setfaq(`${index + 1}`);
                      }}
                      key={index}
                    >
                      <div className="iq-accordion-title">
                        <div className="iq-icon-right">
                          <i
                            aria-hidden="true"
                            className="fa fa-minus active"
                          ></i>
                          <i
                            aria-hidden="true"
                            className="fa fa-plus inactive"
                          ></i>
                        </div>
                        <h4 className="mb-0 accordion-title iq-heading-title">
                          {question}
                        </h4>
                      </div>
                      <div
                        className={`iq-accordion-details ${
                          faq === `${index + 1}` ? "d-block" : "d-none"
                        }`}
                      >
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default FAQ;
