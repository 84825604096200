import statusEnum from "../../enums/statusEnum";
import { handleAllError } from "../../utils/utils";
import { apiSlice } from "../api/apiSlice";
import { contentDetail } from "./contentDetailSlice";

export const contentDetailAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        contentDetail: builder.mutation({
            query: (content_id) => ({
                url: "/v1/content-detail",
                method: "POST",
                body: content_id,
            }),

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data?.code === statusEnum.SUCCESS) {
                        dispatch(contentDetail(data?.data))
                    }
                    handleAllError(data)
                } catch (err) {
                    // do nothing
                }
            },
        }),
    }),
});

export const { useContentDetailMutation } = contentDetailAPI;