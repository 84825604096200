import React, { useEffect, useState } from "react";
import { useAddReviewMutation } from "features/review/reviewApi";
import { Rating } from "react-simple-star-rating";
import { ThreeDotLoader } from "ui/ThreeDotLoader/ThreeDotLoader";
import { errorToast, handleEvent, onChangeHandler } from "utils/utils";
import Reviews from "../Reviews/Reviews";
const initialState = {
  review: "",
  rating: 0,
};

export const DetailsAndReview = ({
  contentDetail: { reviews, id, description, title },
}) => {
  const [currentTab, setCurrentTab] = useState("description");
  const [reviewForm, setReviewForm] = useState(initialState);
  const [reviewList, setReviewList] = useState([]);
  const [addReview, { isLoading }] = useAddReviewMutation();

  useEffect(() => {
    setReviewList(reviews);
  }, [reviews]);

  const onChange = (e) => onChangeHandler(e, setReviewForm);
  const handleSubmit = (e) => {
    handleEvent("Movie Details", "Submit on Movie Details Review Form");
    e.preventDefault();
    if (!reviewForm.rating) return errorToast("The rating field is required.");
    addReview({ ...reviewForm, content_id: id })
      .unwrap()
      .then((res) => {
        if (res?.code === 200) {
          setReviewList(res?.data);
          setReviewForm(initialState);
        }
      });
  };
  return (
    <div
      className="streamit-content-details trending-info g-border iq-rtl-direction"
      style={{
        zIndex: 1,
      }}
    >
      <ul
        className="trending-pills-header d-flex nav nav-pills align-items-center text-center  mb-5 justify-content-center"
        role="tablist"
      >
        <li
          className="nav-item"
          role="presentation"
          onClick={() => setCurrentTab("description")}
        >
          <span
            className="nav-link active show"
            style={{
              background: currentTab === "description" ? "#000000" : "",
              cursor: "pointer",
              color: currentTab === "description" ? "red" : "white",
              borderBottom: currentTab === "description" ? "1px solid red" : "",
            }}
            role="tab"
            aria-selected="true"
          >
            Description
          </span>
        </li>
        <li
          className="nav-item"
          role="presentation"
          onClick={() => setCurrentTab("rate")}
        >
          <span
            className="nav-link"
            style={{
              background: currentTab === "rate" ? "#000000" : "",
              cursor: "pointer",
              color: currentTab === "rate" ? "red" : "white",
              borderBottom: currentTab === "rate" ? "1px solid red" : "",
            }}
            role="tab"
            aria-selected="false"
          >
            Rate & Review
          </span>
        </li>
      </ul>
      <div className="tab-content">
        {currentTab === "description" ? (
          <div
            id="dectripton-1"
            className="tab-pane fade active show"
            role="tabpanel"
          >
            <div className="description-content">
              <p>{description}</p>
            </div>
          </div>
        ) : currentTab === "rate" ? (
          <div id="reviw-1" className="" role="tabpanel">
            <div id="reviews" className="streamit-reviews">
              <div id="comments" className="comments-area validate-form">
                <Reviews reviews={reviewList} />
              </div>
              <div id="review_form_wrapper">
                <div id="review_form">
                  <div id="respond" className="comment-respond">
                    <h3 id="reply-title" className="comment-reply-title mt-0">
                      Be the first to review “{title}”
                    </h3>
                    <form className="comment-form" onSubmit={handleSubmit}>
                      <p className="comment-notes">
                        <span
                          className="required-field-message"
                          aria-hidden="true"
                        >
                          Required fields are marked
                          <span className="required" aria-hidden="true">
                            *
                          </span>
                        </span>
                      </p>
                      <div className="comment-form-rating">
                        <label htmlFor="rating">Your rating</label>
                        <Rating
                          onClick={(rate) =>
                            setReviewForm({ ...reviewForm, rating: rate })
                          }
                          allowFraction={false}
                          ratingValue={reviewForm.rating}
                          transition={true}
                        />
                      </div>
                      <p className="comment-form-comment p-0 mb-3 mt-0">
                        <label htmlFor="comment">
                          Your review&nbsp;<span className="required">*</span>
                        </label>
                        <textarea
                          id="review"
                          name="review"
                          cols="45"
                          rows="8"
                          required
                          value={reviewForm.review}
                          onChange={onChange}
                        ></textarea>
                      </p>
                      <p className="form-submit">
                        <button
                          name="submit"
                          type="submit"
                          id="submit"
                          className="btn btn-hover iq-button"
                          value="Submit"
                          style={{
                            cursor: isLoading ? "not-allowed" : "pointer",
                          }}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <ThreeDotLoader
                              color="#fff"
                              height={20}
                              width={35}
                            />
                          ) : (
                            <span>
                              <i className="ri-play-fill"></i>Submit
                            </span>
                          )}
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
