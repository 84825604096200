import React from 'react'
import { Navbar } from 'react-bootstrap'

const BurgerMenu = () => {
    return (
        <Navbar.Toggle className="c-toggler">
            <div className="navbar-toggler-icon"  >
                <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
            </div>
        </Navbar.Toggle>
    )
}
export default BurgerMenu
