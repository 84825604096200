import React from "react";
import { useWindowWidth } from "hooks/useWindowWidth";

const DataNotFound = ({ type = "movies" }) => {
  const width = useWindowWidth();
  return (
    <div
      className="alert alert-danger vh-100 d-flex justify-content-center align-items-center"
      role="alert"
      style={{
        maxHeight: `${
          width < 576 ? "calc(50vh - 70px)" : "calc(100vh - 350px)"
        }`,
      }}
    >
      No {type} found!
    </div>
  );
};

export default DataNotFound;
