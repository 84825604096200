// eslint-disable-next-line
import "./assets/css/bootstrap.min.css";
import "./assets/css/typography.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import AppRoutes from "./router/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthCheck from "./hooks/auth/useAuthCheck";
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_G_MEASUREMENT_ID);
function App() {
  const authChecked = useAuthCheck();
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  return !authChecked ? (
    <div>Checking authentication...</div>
  ) : (
    <div className="App">
      <AppRoutes />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
