/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MovieDetailsBanner } from "components/MovieDetails/MovieDetailsBanner";
import { MovieTrailerSection } from "components/MovieDetails/MovieTrailerSection";
import { useSelector } from "react-redux";
import { DetailsAndReview } from "components/MovieDetails/DetailsAndReview";

const MovieDetails = () => {
  const { contentDetail } = useSelector((state) => state.contentDetail);
  return (
    <div className="main-content movie">
      <MovieDetailsBanner />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <MovieTrailerSection data={contentDetail} />
            <DetailsAndReview contentDetail={contentDetail} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieDetails;
