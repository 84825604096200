import { apiSlice } from "../api/apiSlice";
import { FAQ } from "../../service/apiEndPoints";
import { handleAllError } from "../../utils/utils";

export const faqAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFAQ: builder.query({
            query: () => FAQ,
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    handleAllError(data);
                } catch (err) {
                    // do nothing
                }
            },
        }),

    }),
});

export const { useGetFAQQuery } = faqAPI;