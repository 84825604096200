import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { handleEvent } from "utils/utils";
import { WatchTrailerButton } from "ui/Buttons/Buttons";
import { useSelector } from "react-redux";

const TrailerPreviewSection = ({
  playTrailerFnc = () => {},
  setRegistrationModal = () => {},
  category = "",
  label = "",
}) => {
  const authUser = useSelector((state) => state.auth?.user);
  return (
    <Col
      xl="5"
      lg="5"
      md="12"
      className="trailor-video text-center d-none d-lg-block"
    >
      <Link
        to="#"
        onClick={() => {
          handleEvent(category, "Clicked on Watch Trailer Button", label);
          if (authUser?.status !== 3) {
            playTrailerFnc();
            return;
          }
          setRegistrationModal();
        }}
        className="video-open playbtn"
      >
        <WatchTrailerButton />
      </Link>
    </Col>
  );
};

export default TrailerPreviewSection;
