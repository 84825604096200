import { apiSlice } from "../api/apiSlice";
import { REVIEW } from "../../service/apiEndPoints";
import statusEnum from "../../enums/statusEnum";
import { handleAllError, successToast } from "../../utils/utils";

export const review = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addReview: builder.mutation({
            query: (body) => {
                return ({
                    url: REVIEW,
                    method: "POST",
                    body,
                })
            },

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data?.code === statusEnum.SUCCESS) {
                        successToast(data?.message[0]);
                    }

                    handleAllError(data);
                } catch (err) {
                    // do nothing
                }
            },
        }),
    }),
});

export const { useAddReviewMutation } = review;