import React from "react";
import { Link } from "react-router-dom";

const Starring = ({ actor }) => {
  return (
    <div
      id="iq-favorites"
      className="s-margin detail-cast-list iq-rtl-direction mt-0 starring"
    >
      <div className="row m-0">
        <div className="col-sm-12 overflow-hidden p-0">
          <div className="iq-main-header d-flex align-items-center justify-content-between iq-ltr-direction">
            <h4 className="main-title">Starring</h4>
          </div>
          <div className="favorites-contens iq-smovie-slider">
            <ul className="inner-slider list-inline row p-0  iq-ltr-direction">
              {actor?.map((item, index) => {
                return (
                  <li
                    className=" slide-item iq-ltr-direction col-xl-3 col-lg-4 col-md-4 col-6"
                    key={index}
                  >
                    <div className="cast-images position-relative row mx-0">
                      <div className="col-sm-4 col-12 img-box p-0">
                        <img
                          src={item?.image}
                          className="person__poster--image img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="col-sm-8 col-12 block-description starring-desc ">
                        <h6 className="iq-title">
                          <Link
                            to="#"
                            style={{
                              cursor: "context-menu",
                            }}
                            tabIndex="0"
                          >
                            {item?.name}
                          </Link>
                        </h6>
                        {/* <div className="video-time d-flex align-items-center my-2">
                                                        <span className="text-white">As Adnan</span>
                                                    </div> */}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Starring;