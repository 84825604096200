/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleEvent, onChangeHandler } from "utils/utils";
import { ThreeDotLoader } from "ui/ThreeDotLoader/ThreeDotLoader";
import { useNavigate } from "react-router-dom";
import { leaderBoardApi } from "features/leaderBoard/leaderBoardApi";
import { useProfileUpdateMutation } from "features/auth/authApi";
import "react-datepicker/dist/react-datepicker.css";

const UserProfile = () => {
  const { image, name, date_of_birth, msisdn } = useSelector(
    (state) => state.auth.user
  );
  const [profileUpdate, { isLoading: isProfileUpdateLoading }] =
    useProfileUpdateMutation();
  const auth = useSelector((state) => state.auth);
  const [user, setUser] = React.useState({
    name: name || "",
    date_of_birth: date_of_birth || "",
    msisdn: msisdn || "",
    image: image || "",
    rawImage: "",
  });

  const onChange = (e) => onChangeHandler(e, setUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEvent("Profile Update Form", "Clicked on Submit Button");
    const formData = new FormData();
    formData.append("name", user?.name);
    formData.append("image", user?.rawImage);
    formData.append("dob", user?.date_of_birth);
    if (auth?.notMadlyUser?.point) {
      formData.append("point", auth?.notMadlyUser?.point);
    }
    profileUpdate(formData)
      .unwrap()
      .then((res) => {
        if (res?.code === 200) {
          if (auth?.notMadlyUser?.point) {
            dispatch(leaderBoardApi.util.resetApiState());
            navigate("/leaderboard");
          } else {
            navigate("/");
          }
        }
      });
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    // File reader
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUser((prev) => ({
        ...prev,
        image: reader.result,
        rawImage: file,
      }));
    };
  };

  return (
    <section className="m-profile manage-p">
      <div className="container h-100">
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-lg-10">
            <div className="sign-user_card bg-white">
              <div className="row">
                <div className="col-lg-2">
                  <div className="upload_profile d-inline-block">
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                      src={user?.image}
                      className="profile-pic rounded-circle img-fluid"
                      alt="user"
                    />
                    <div className="p-image">
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        <i
                          className="ri-pencil-line upload-button text-white"
                          htmlFor="file-upload"
                        ></i>
                        <input
                          className="file-upload"
                          id="file-upload"
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 device-margin">
                  <div className="profile-from">
                    <h4 className="mb-3 text-black">
                      {auth?.notMadlyUser?.point
                        ? "Complete your registration to claim points!"
                        : "Profile"}
                    </h4>
                    <form className="mt-4" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Name *</label>
                        <input
                          type="text"
                          name="name"
                          value={user.name}
                          onChange={onChange}
                          className="form-control mb-0"
                          id=""
                          placeholder="Enter Your Name"
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Date of Birth *</label>
                        <input
                          type="date"
                          name="date_of_birth"
                          //   readOnly
                          //   disabled
                          //   style={{ cursor: "not-allowed" }}
                          onChange={onChange}
                          className="form-control date-input basicFlatpickr mb-0"
                          placeholder="Select Date"
                          value={date_of_birth}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Phone *</label>
                        <input
                          type="text"
                          readOnly
                          disabled
                          style={{ cursor: "not-allowed" }}
                          name="phone"
                          value={user?.msisdn}
                          onChange={onChange}
                          className="form-control mb-0"
                          id=""
                          placeholder="Enter Your Phone Number"
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="d-flex">
                        <button
                          type="submit"
                          className="btn btn-hover"
                          disabled={isProfileUpdateLoading}
                          style={{
                            cursor: isProfileUpdateLoading
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          {isProfileUpdateLoading ? (
                            <ThreeDotLoader
                              color="#fff"
                              height={20}
                              width={35}
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;
