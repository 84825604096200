import React from "react";
import { Link } from "react-router-dom";
import { handleEvent } from "utils/utils";

const CardTitle = ({
  path = "",
  label = "",
  name = "",
  mb = 0,
  onClick = () => {
    handleEvent(label, "Clicked on Title", name);
  },
  is_upcoming = false,
  mt = "",
}) => {
  return (
    <h5 className={`iq-title mb-${mb} mt-${mt}`}>
      <Link
        to={path}
        onClick={onClick}
        style={{
          cursor: is_upcoming ? "not-allowed" : "pointer",
          color: is_upcoming ? "#c3c3c3" : "#fff",
        }}
        title={is_upcoming ? "Coming Soon" : name}
      >
        {name}
      </Link>
    </h5>
  );
};

export default CardTitle;
