import React, { useEffect } from "react";
import { useGameQuery } from "features/games/gamesApi";
import { useNavigate, useParams } from "react-router-dom";
import { GAMES_STATUS } from "service/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { setNotMadlyUser } from "features/auth/authSlice";
import GameWinOrLoseModal from "./GameWinOrLoseModal";
import CustomLoader from "ui/Loader/CustomLoader";

const GameDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGameQuery(id);
  const auth = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = React.useState(false);
  const [gameStatus, setGameStatus] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!data?.data?.session_id) return clearInterval(interval);
      fetch(
        `${process.env.REACT_APP_API_URL}${GAMES_STATUS}?session_id=${data?.data?.session_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.data?.point) {
            setGameStatus(data?.data);
            setModalShow(true);
            if (auth?.user?.status === 1) {
              setTimeout(() => {
                navigate("/leaderboard");
              }, 5000);
            } else if (auth?.user?.status === 3) {
              dispatch(setNotMadlyUser(data?.data));
            }
          }
        });
    }, 2000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [data?.data?.session_id, auth, navigate]);

  if (isLoading) return <CustomLoader />;

  return (
    <div className="main-content" style={{ paddingTop: "50px" }}>
      <GameWinOrLoseModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        gameStatus={gameStatus}
      />

      <div className="show-movie mt-4">
        <div className="container-fluid pb-4 d-none d-lg-block">
          <iframe
            src={data?.data?.games_url}
            width="100%"
            title="Iframe Example"
            style={{ minHeight: "calc(100vh - 325px)" }}
          ></iframe>
        </div>
        <div className="container-fluid pb-4 d-none sm-block">
          <iframe
            src={data?.data?.games_url}
            width="100%"
            title="Iframe Example"
            style={{ minHeight: "90vh" }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GameDetails;
