import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLogoutMutation } from "features/auth/authApi";
import Card from "../Card";

const UserProfileDropdown = () => {
  const [logout, { isLoading }] = useLogoutMutation();
  const { image, name, total_point, current_position } = useSelector(
    (state) => state.auth?.user
  );
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    logout();
  };

  return (
    <Dropdown.Menu className="iq-sub-dropdown iq-user-dropdown" align="right">
      <Card className="shadow-none m-0">
        <Card.Body className="p-0 pl-3 pr-3">
          <Link href="/setting" className="iq-sub-card setting-dropdown">
            <div className="media align-items-center">
              <div className="right-icon">
                <img
                  src={image}
                  className="img-fluid avatar-40 rounded-circle"
                  alt="user"
                  loading="lazy"
                />
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 ">{name} </h6>
                <p className="mb-0">
                  {" "}
                  Points - {total_point || 0} | Rank - {current_position || 0}
                </p>
              </div>
            </div>
          </Link>
          <Link to="/leaderboard" className="iq-sub-card setting-dropdown">
            <div className="media align-items-center">
              <div className="right-icon">
                <i className="ri-trophy-line text-primary"></i>
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 ">Claim Your Points</h6>
              </div>
            </div>
          </Link>
          <Link to="/manage-profile" className="iq-sub-card setting-dropdown">
            <div className="media align-items-center">
              <div className="right-icon">
                <i className="ri-file-user-line text-primary"></i>
              </div>
              <div className="media-body ml-3">
                <h6 className="my-0 ">Profile</h6>
              </div>
            </div>
          </Link>
          {/* {status !== 3 && (
            <Link to="/setting" className="iq-sub-card setting-dropdown">
              <div className="media align-items-center">
                <div className="right-icon">
                  <i className="ri-settings-4-line text-primary"></i>
                </div>
                <div className="media-body ml-3">
                  <h6 className="my-0 ">Settings</h6>
                </div>
              </div>
            </Link>
          )} */}
          <Link
            to={"#"}
            onClick={handleLogout}
            className="iq-sub-card setting-dropdown"
          >
            <div className="media align-items-center">
              <div className="right-icon">
                <i className="ri-logout-circle-line text-primary"></i>
              </div>
              <div className="media-body ml-3">
                <h6
                  className="my-0 "
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    opacity: isLoading ? 0.5 : 1,
                  }}
                >
                  Logout
                </h6>
              </div>
            </div>
          </Link>
        </Card.Body>
      </Card>
    </Dropdown.Menu>
  );
};

export default UserProfileDropdown;
