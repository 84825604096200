import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import CustomToggle from "../dropdowns";
import Card from "../Card";
import { Link, useLocation } from "react-router-dom";
// import thumb1 from 'assets/images/notify/thumb-1.jpg'
import { useDispatch, useSelector } from "react-redux";
import { searchAPI } from "features/search/searchAPI";
import { getReleaseDate } from "utils/utils";
import UserProfileDropdown from "./UserProfileDropdown";
import CirclesLoader from "ui/Circles/CirclesLoader";
import searchIcon from "assets/images/search.svg";

const ThreeDotToggle = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [showMoreMenu, setShowMoreMenu] = React.useState(false);
  const dropdownRef = useRef(null);
  const searchedList = useSelector((state) => state.search.searchedList);
  const { image, status } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (search) {
      const timeoutId = setTimeout(() => {
        setLoading(true);
        dispatch(searchAPI.endpoints.search.initiate(search)).then(
          ({ data }) => {
            setLoading(false);
          }
        );
      }, 400); // delay of 400ms

      return () => clearTimeout(timeoutId);
    }
  }, [search, dispatch]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  // const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //         setShowDropdown(false);
  //     }
  // };

  useEffect(() => {
    setShowDropdown(false);
    setShowMoreMenu(false);
    setSearch("");
  }, [currentPath]);
  return (
    <Dropdown className="mobile-more-menu">
      <Dropdown.Toggle
        to="#"
        as={CustomToggle}
        variant="more-toggle"
        onClick={() => setShowMoreMenu((prev) => !prev)}
      >
        <i className="ri-more-line"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="more-menu" show={showMoreMenu}>
        <div className="navbar-right position-relative">
          <ul className="d-flex align-items-center justify-content-end list-inline m-0">
            <Dropdown as="li" className="nav-item nav-icon">
              <Dropdown.Menu
                className="search-box iq-search-bar d-search p-0 m-0"
                align="right"
                style={{
                  ...(!searchedList.length &&
                    !search && {
                      border: "none",
                    }),
                }}
              >
                {status !== 3 && (
                  <Form
                    className="searchbox"
                    style={{
                      ...(!searchedList.length &&
                        !search && {
                          border: "none",
                        }),
                    }}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        className="text search-input font-size-12"
                        required
                        placeholder="Type here to search..."
                        onChange={handleInputChange}
                        value={search}
                      />
                      {/* <i className="search-link ri-search-line"></i> */}
                      <img src={searchIcon} alt="" />
                    </div>
                  </Form>
                )}
                <Dropdown.Menu
                  className="iq-sub-dropdown searched-list"
                  align="right"
                  style={{
                    border: "none",
                  }}
                >
                  <Card className="shadow-none m-0">
                    <Card.Body>
                      {loading ? (
                        <>
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: "200px",
                            }}
                          >
                            <CirclesLoader />
                          </div>
                        </>
                      ) : (
                        search &&
                        searchedList?.map(
                          ({ id, thumbnail, title, release_date }, index) => {
                            return (
                              <Link
                                to={`/show-detail/movie/${id}`}
                                className="iq-sub-card"
                                key={index}
                                onClick={() => {
                                  setShowDropdown(false);
                                  setSearch("");
                                  const customToggle =
                                    document.querySelector(".device-search");
                                  customToggle.click();
                                }}
                              >
                                <div className="media align-items-center">
                                  <img
                                    style={{
                                      width: "64px",
                                      height: "64px",
                                    }}
                                    src={thumbnail}
                                    className="img-fluid mr-3"
                                    alt="streamit"
                                  />
                                  <div className="media-body">
                                    <h6 className="mb-0 ">{title}</h6>
                                    {release_date && (
                                      <small className="font-size-12">
                                        {getReleaseDate(release_date)}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        )
                      )}
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown.Menu>
              {status !== 3 && (
                <Dropdown.Toggle
                  as={CustomToggle}
                  href="#"
                  variant="search-toggle device-search"
                >
                  <i className="ri-search-line"></i>
                </Dropdown.Toggle>
              )}
            </Dropdown>
            <Dropdown as="li" className="nav-item nav-icon">
              <Link
                to="/leaderboard"
                className="nav-link nav-link-lg"
                title="Leaderboard"
              >
                <i className="ri-trophy-line"></i>
              </Link>
            </Dropdown>
            <Dropdown
              as="li"
              className="nav-item nav-icon"
              show={showDropdown}
              onClick={() => setShowDropdown(!showDropdown)}
              ref={dropdownRef}
            >
              <Dropdown.Toggle
                href="#"
                as={CustomToggle}
                variant="search-toggle"
              >
                <div
                  className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                  data-toggle="search-toggle"
                >
                  <img
                    src={image}
                    className="img-fluid avatar-40 rounded-circle"
                    alt="user"
                  />
                </div>
              </Dropdown.Toggle>
              <UserProfileDropdown />
            </Dropdown>
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ThreeDotToggle;
