import React from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { useDispatch } from "react-redux";
import { isReactModal } from "features/videoPlayerSlice/modalVideoPlayerSlice";
import IframePlayer from "../IframePlayer/IframePlayer";

const TrailerPlayer = ({ trailer, isOpen, setOpen }) => {
  const dispatch = useDispatch();
  return (
    <ModalVideo
      channel="custom"
      autoplay
      isOpen={isOpen}
      // url={trailer} allowFullScreen={true}
      onClose={() => {
        setOpen(false);
        dispatch(isReactModal());
      }}
      children={<IframePlayer stream_url={trailer} />}
    />
  );
};


export default TrailerPlayer;