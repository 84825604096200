import { toast } from "react-toastify";
import { gsap } from "gsap";
import statusEnum from "../enums/statusEnum";
import ReactGA from "react-ga4";
import {
  MOVIE_LIST,
  TV_SHOW_LIST,
  BANGLA_NATOK_LIST,
  //   MUSIC_VIDEO_LIST,
  HERO_CONTENT_ALL,
  TRENDING_NOW_ALL,
} from "../service/apiEndPoints";

export const genresList = [
  "Hindi",
  "Tamil",
  "Punjabi",
  "English",
  "Comedies",
  "Action",
  "Romance",
  "Dramas",
  "Bollywood",
  "Hollywood",
  "Children & Family",
  "Award-Winning",
];

export const categories = [
  "Dramas",
  "Historical",
  "Movie",
  "Movie Trailers",
  "Trailers",
  "TV Comedies",
  "TV Rumors",
  " TV Series",
];

export const tags = [
  "Action",
  "Comedies",
  "comedy",
  "Drama",
  "Dramas",
  "Historical",
  "horr",
  "Horror",
  "Movie",
  "Movie Trailers",
  "Mystery",
  "Rumors",
  "thrill",
  "Thriller",
  "Trailers",
  "Tv Rumors",
  "TV Series",
];

export const errorToast = (message, position = toast.POSITION.BOTTOM_LEFT) =>
  toast.error(message, { position });

export const successToast = (message, position = toast.POSITION.BOTTOM_LEFT) =>
  toast.success(message, { position });

export const warningToast = (message, position = toast.POSITION.BOTTOM_LEFT) =>
  toast.warning(message, { position });

export const onChangeHandler = ({ target }, setState) => {
  const { name, value } = target;
  setState((prev) => ({
    ...prev,
    [name]: value,
  }));
};

export const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

export const animationInit = () => {
  if (
    document.querySelector(".swiper-container .swiper-slide-active") !== null
  ) {
    const gsapElem = document
      .querySelector(".swiper-container .swiper-slide-active")
      .querySelectorAll('[data-iq-gsap="onStart"]');

    Array.from(gsapElem, (elem) => {
      return gsapAnimate.onStartEnd(elem);
    });
  }
};

export const getReleaseDate = (date) => {
  const releaseDate = new Date(date);
  const releaseYear = releaseDate.getFullYear();
  const releaseMonth = releaseDate.toLocaleString("default", {
    month: "short",
  });
  const release = `${releaseMonth} ${releaseYear}`;
  return release;
};

export const episodeReleaseData = (date) => {
  // Format: October 1, 2020
  const releaseDate = new Date(date);
  const releaseYear = releaseDate.getFullYear();
  const releaseMonth = releaseDate.toLocaleString("default", { month: "long" });
  const releaseDay = releaseDate.getDate();
  const release = `${releaseMonth} ${releaseDay}, ${releaseYear}`;
  return release;
};

export const durationFormat = (duration) => {
  // duration = 298 second, convert to 2hr : 42mins format
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  // const seconds = duration - (hours * 3600) - (minutes * 60);
  // Format should be convert to 2hr : 42mins format Something like this
  const hoursFormat = hours < 10 ? `0${hours}` : hours;
  const minutesFormat = minutes < 10 ? `0${minutes}` : minutes;
  // const secondsFormat = seconds < 10 ? `0${seconds}` : seconds;
  const durationFormat = `${hoursFormat}hr : ${minutesFormat}mins`;
  return durationFormat;
};

export const updateAddToFavourite = async (
  dispatch,
  moviesApi,
  pathName,
  content_id,
  queryFulfilled,
  endpoint
) => {
  const updateMovie = dispatch(
    moviesApi.util.updateQueryData(pathName, endpoint, (draft) => {
      const existingContent = JSON.parse(JSON.stringify(draft));
      // console.log('existingContent', existingContent)
      const findOutContent = existingContent?.data?.find(
        (item) => item?.id === content_id
      );
      if (findOutContent) {
        findOutContent.user_favourite = !findOutContent?.user_favourite;
        findOutContent.total_favourite = findOutContent?.user_favourite
          ? findOutContent?.total_favourite + 1
          : findOutContent?.total_favourite - 1;

        // let clsName = document.getElementsByClassName(`count_${findOutContent?.id}`)
        // for (let i = 0; i < clsName.length; i++) {
        //     console.log(document.getElementsByClassName(`count_${findOutContent?.id}`)[i])
        //     document.getElementsByClassName(`count_${findOutContent?.id}`)[i].innerText = 232
        // }
      }

      // console.log('existingContent', findOutContent)
      return existingContent;
    })
  );

  try {
    const { data } = await queryFulfilled;
    if (data?.code === statusEnum.SUCCESS) {
      successToast(data?.message[0]);
    } else {
      errorToast(data?.message[0]);
      updateMovie();
    }
  } catch (err) {
    updateMovie.undo();
  }
};

export const getReleaseYear = (date) => {
  // 1994-09-22
  const releaseDate = new Date(date);
  const releaseYear = releaseDate.getFullYear();
  return releaseYear;
};

export const commaseparated = (list = [], key) =>
  list?.map((item) => item[key]).join(", ");

export const handleAllError = (data) => {
  if (data?.code === statusEnum.SERVER_ERROR) {
    errorToast(data?.message[0]);
  } else if (data?.code === statusEnum.VALIDATION_ERROR) {
    errorToast(data?.message[0]);
  } else if (data?.code === statusEnum.CONFLICT_ERROR) {
    errorToast(data?.message[0]);
  } else if (data?.code === statusEnum.NOT_FOUND) {
    errorToast(data?.message[0]);
  }
};

export const videoKeyBoardShortCut = (player) => {
  player.on("keydown", (e) => {
    const playerVolume = player.volume();
    const playerCurrentTime = player.currentTime();
    switch (e.code) {
      case "Space":
        if (player.paused()) {
          player.play();
        } else {
          player.pause();
        }
        break;
      case "ArrowRight":
        player.currentTime(playerCurrentTime + 10);
        break;
      case "ArrowLeft":
        player.currentTime(playerCurrentTime - 10);
        break;
      case "ArrowUp":
        player.volume(playerVolume + 0.1);
        break;
      case "ArrowDown":
        player.volume(playerVolume - 0.1);
        break;
      case "KeyM":
        player.volume(0);
        break;
      default:
        return;
    }
  });
};

export const playerButtons = (player, videojs, playIconSvg, pauseIconSvg) => {
  // Replace the default videojs icon with custom icon. If the video is pause, then show play icon, otherwise show pause icon
  player.on("play", () => {
    const playButton = document.querySelector(".vjs-play-control");
    playButton.innerHTML = `<img
        class="play-icon"
        style="width: 20px; height: 45px"
        src=${pauseIconSvg} alt="play-icon" />`;
  });

  player.on("pause", () => {
    const playButton = document.querySelector(".vjs-play-control");
    playButton.innerHTML = `<img 
        class="play-icon"
        style="width: 20px; height: 45px"
        src=${playIconSvg} alt="play-icon" />`;
  });
  //  get The Button Component of VideoJs
  var Button = videojs.getComponent("Button");

  //  implement the Rewind Button
  var rewind = videojs.extend(Button, {
    constructor: function () {
      Button.apply(this, arguments);
      this.addClass("rewindIcon");
      /* initialize your button */
      // Skip back icon
      this.el().innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7V416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7V416c0 17.7-14.3 32-32 32s-32-14.3-32-32V96C0 78.3 14.3 64 32 64s32 14.3 32 32V214.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96V214.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96V416c0 12.4-7.2 23.7-18.4 29z"/></svg>`;
    },
    handleClick: function () {
      player.currentTime(player.currentTime() - 10);
    },
  });

  videojs.registerComponent("rewind", rewind);
  player.getChild("ControlBar").addChild("rewind", {}, 2);

  //  implement the Fastforward Button
  var fastForward = videojs.extend(Button, {
    constructor: function () {
      Button.apply(this, arguments);
      this.addClass("fast-forward-icon");
      /* initialize your button */
      // Skip forward icon
      this.el().innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M18.4 445c11.2 5.3 24.5 3.6 34.1-4.4L224 297.7V416c0 12.4 7.2 23.7 18.4 29s24.5 3.6 34.1-4.4L448 297.7V416c0 17.7 14.3 32 32 32s32-14.3 32-32V96c0-17.7-14.3-32-32-32s-32 14.3-32 32V214.3L276.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S224 83.6 224 96V214.3L52.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S0 83.6 0 96V416c0 12.4 7.2 23.7 18.4 29z"/></svg>`;
    },
    handleClick: function () {
      player.currentTime(player.currentTime() + 10);
    },
  });
  videojs.registerComponent("fastForward", fastForward);
  player.getChild("ControlBar").addChild("fastForward", {}, 3);
};

export const getCurrentCategory = (type, returnEndPoint = true) => {
  switch (type) {
    case "movie":
      return returnEndPoint ? MOVIE_LIST : "Movie";
    case "tv-series":
      return returnEndPoint ? TV_SHOW_LIST : "TV Series";
    // case "music-video":
    //   return returnEndPoint ? MUSIC_VIDEO_LIST : "Music Video";
    case "bangla-natok":
      return returnEndPoint ? BANGLA_NATOK_LIST : "Bangla Natok";
    case "hero-contents":
      return returnEndPoint ? HERO_CONTENT_ALL : "Hero Contents";
    case "trending-now":
      return returnEndPoint ? TRENDING_NOW_ALL : "Trending Now";
    default:
      return "";
  }
};

export const handleEvent = (
  category,
  action,
  label,
  value,
  nonInteraction,
  transport
) => {
  return ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
    transport,
  });
};

export const valid_mobile = (value) => {
  if (!value) return;
  if (value < 11) return false;
  if (isNaN(value)) {
    value = translteBanglaToEngNum(value);
  }

  const valid_number = value.match("(?:\\+88|88)?(0?1[3-9]\\d{8})");

  if (valid_number) {
    return valid_number[1];
  } else {
    return false;
  }
};

const translteBanglaToEngNum = (num_str) => {
  var bengali = {
    "০": 0,
    "১": 1,
    "২": 2,
    "৩": 3,
    "৪": 4,
    "৫": 5,
    "৬": 6,
    "৭": 7,
    "৮": 8,
    "৯": 9,
  };
  var changed_nun = "";
  num_str
    ?.toString()
    .split("")
    .forEach((l) => {
      if (isNaN(l)) {
        changed_nun += bengali[l];
      } else {
        changed_nun += l;
      }
    });
  return changed_nun;
};
