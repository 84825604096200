import React from "react";

export const MediaCard = ({ children }) => {
  return (
    <div className="block-images1 block-images position-relative">
      {children}
    </div>
  );
};

export const SliderCard = ({ style = {}, children }) => {
  return (
    <div id="favorites-contens" className="favourite-slider" style={style}>
      {children}
    </div>
  );
};
