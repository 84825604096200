import { apiSlice } from "../api/apiSlice";
import { handleAllError } from "../../utils/utils";
import { GLOBAL_SEARCH } from "../../service/apiEndPoints";
import { searchedList } from "./searchedSlice";

export const searchAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        search: builder.query({
            query: (value) => `${GLOBAL_SEARCH}${value}`,

            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(searchedList(data?.data))
                    // if (data?.code === statusEnum.SUCCESS) {
                    //     dispatch(contentDetail(data?.data))
                    // }

                    handleAllError(data);
                } catch (err) {
                    // do nothing
                }
            },
        }),
    }),
});

export const { useSearchQuery } = searchAPI;