import React, { useEffect } from "react";
import logo from "assets/images/logo.png";
import { useOtpMutation } from "features/auth/authApi";
import { ThreeDotLoader } from "ui/ThreeDotLoader/ThreeDotLoader";
import { useNavigate } from "react-router-dom";

const Otp = ({ msisdn, isOtp, setIsOtp, login }) => {
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otp, { data, isLoading, responseError }] = useOtpMutation();
  const [counter, setCounter] = React.useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.code === 200 && data?.data?.token) {
      navigate("/");
    }
  }, [data, responseError, navigate]);

  const submitOtp = (e) => {
    e.preventDefault();
    otp({ otp: otpNumber, msisdn });
  };

  useEffect(() => {
    if (isOtp) {
      const interval = setInterval(() => {
        if (!counter) {
          // setIsOtp(false);
          return clearInterval(interval);
        }
        setCounter((counter) => counter - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtp && counter > 0]);

  const resendOtp = () => {
    setOtpNumber("");
    login({ msisdn })
      .unwrap()
      .then(({ code }) => {
        if (code === 200) {
          setIsOtp(true);
          setCounter(60);
        }
      });
  };
  return (
    <section className="sign-in-page">
      <div className="container">
        <div className="row justify-content-center align-items-center height-self-center">
          <div className="col-lg-5 col-md-12 align-self-center">
            <div className="sign-user_card bg-white border-radius-6">
              <div className="sign-in-page-data">
                <div className="sign-in-from w-100 m-auto text-center">
                  <img
                    src={logo}
                    alt=""
                    width="100px"
                    className="mb-3 login-logo"
                  />
                  <p className="mb-0 text-black"> Welcome to </p>
                  <h3 className="mb-3 text-center logo-text text-uppercase text-secondary">
                    {" "}
                    Breakstation!{" "}
                  </h3>
                  <form className="mt-4" onSubmit={submitOtp}>
                    <div className="form-group">
                      <label htmlFor="OTP" className="fw-normal mb-2">
                        {" "}
                        Enter OTP{" "}
                      </label>
                      <div className="">
                        <input
                          type="text"
                          className="form-control mb-0 mr-2 text-black"
                          id="1"
                          placeholder="Enter OTP Code"
                          autoComplete="off"
                          required
                          onChange={({ target }) => setOtpNumber(target.value)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn"
                          type="button"
                          onClick={() => resendOtp()}
                          disabled={counter > 0}
                          style={{
                            cursor: counter > 0 ? "not-allowed" : "pointer",
                            color: "#007bff",
                            fontSize: "14px",
                            fontWeight: "500",
                            margin: "0",
                            padding: "0",
                          }}
                        >
                          {counter > 0 ? (
                            <span className="text-muted">
                              Resend OTP in: {counter}s
                            </span>
                          ) : (
                            "Resend OTP"
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="sign-info">
                      <button
                        type="submit"
                        className="btn btn-hover"
                        disabled={isLoading}
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                      >
                        {isLoading ? (
                          <ThreeDotLoader color="#fff" height={20} width={35} />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Otp;