import React from "react";
import { Link } from "react-router-dom";
import { durationFormat, episodeReleaseData } from "../../utils/utils";
import Fade from "react-reveal/Fade";
import TvSeriesModal from "../VideoPlayer/TvSeries/TvSeriesModal";

const SessionalVideo = ({ sessions }) => {
  const [currentTab, setCurrentTab] = React.useState(1);
  const [tvSeriesData, setTvSeriesData] = React.useState({});
  const [modalShow, setModalShow] = React.useState(false);

  const seasonCode = (sesonIndex, episodeIndex) => {
    // Format S01E01
    let newCode = "";
    if (sesonIndex < 10) {
      newCode = `S0${sesonIndex + 1}E0${episodeIndex + 1}`;
    } else {
      newCode = `S${sesonIndex + 1}E${episodeIndex + 1}`;
    }

    return newCode;
  };

  return (
    <section className="show-movie-section">
      <div className="iq-custom-select d-inline-block sea-epi">
        <select name="cars" className="form-control season-select">
          {sessions?.map(({ title }, index) => (
            <option key={index} value={title}>
              {title}
            </option>
          ))}
        </select>
      </div>
      <div className="trending-custom-tab">
        <div
          id="tab-title-info-modified"
          className="tab-title-info position-relative mt-2 mb-2"
        >
          <ul
            className="trending-pills nav nav-pills text-center iq-ltr-direction"
            role="tablist"
          >
            {sessions?.map(({ title, id }, index) => {
              return (
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setCurrentTab(id)}
                  key={index}
                >
                  <span
                    className="nav-link active show"
                    style={{
                      // background: currentTab === 'episode' ? '#000000' : '',
                      cursor: "pointer",
                      // color: currentTab === 'description' ? 'red' : 'white',
                      // borderBottom: currentTab === 'description' ? '1px solid red' : ''
                    }}
                    role="tab"
                    aria-selected="true"
                  >
                    {title}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="tab-content" id="nav-tabContent">
          <div
            id="episodes"
            className=" tab-pane animated fadeInUp active show"
          >
            <div className="row episodes list-inline p-0 mb-0 iq-rtl-direction ">
              {sessions?.map(({ episodes }, i) => {
                // If currentTab === 1 then show session 1 episodes else if currentTab === 2 then show session 2 episodes and so on
                if (currentTab === i + 1) {
                  return episodes.map(
                    (
                      {
                        cover_image,
                        title,
                        duration,
                        stream_id,
                        release_date,
                        stream_url,
                        thumbnail,
                        thumbnail_sprite,
                      },
                      index
                    ) => {
                      return (
                        <Fade bottom key={index}>
                          <div className="e-item col-lg-3 col-sm-12 col-md-6 mb-2">
                            <div className="block-image position-relative">
                              <a href="/#">
                                <img
                                  src={cover_image}
                                  className="img-fluid img-zoom season-img"
                                  alt=""
                                  loading="lazy"
                                />
                              </a>
                              <div className="episode-number">
                                {seasonCode(i, index)}
                              </div>
                              <div className="episode-play-info">
                                <div className="episode-play">
                                  <Link
                                    to={`#`}
                                    onClick={() => {
                                      setTvSeriesData({
                                        stream_url,
                                        thumbnail: cover_image,
                                        stream_id,
                                        thumbnail_sprite,
                                        duration,
                                      });
                                      setModalShow((prev) => !prev);
                                    }}
                                  >
                                    <i className="ri-play-fill"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="epi-desc p-2">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <span className="text-white rel-date">
                                  {episodeReleaseData(release_date)}
                                </span>
                                <span className="text-primary run-time">
                                  {durationFormat(duration)}
                                </span>
                              </div>
                              {/* <Link to={`/show-detail/movie/${stream_id}`}> */}
                              <h5 className="epi-name text-white mb-0">
                                {title}
                              </h5>
                              {/* </Link> */}
                            </div>
                          </div>
                        </Fade>
                      );
                    }
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <TvSeriesModal
          {...tvSeriesData}
          open={modalShow}
          setOpen={setModalShow}
        />
      )}
    </section>
  );
};

export default SessionalVideo;
