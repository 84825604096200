import React from "react";
// import VideoJS from "../VideoPlayer/VideoJS";
import { useSelector } from "react-redux";
import IframePlayer from "../VideoPlayer/IframePlayer/IframePlayer";
export const MovieDetailsBanner = () => {
  const {
    contentDetail: {
      stream_url,
      // stream_id,
      // thumbnail_sprite,
      // category,
      // duration,
      cover_image,
    },
  } = useSelector((state) => state.contentDetail);
  // const millisecondDuration = duration * 1000;
  // console.log({ duration, millisecondDuration })
  // function isMobile() {
  //   const regex =
  //     /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  //   return regex.test(navigator.userAgent);
  // }

  return (
    <div className="show-movie">
      <div className="container-fluid">
        <div
          className="banner-wrapper overlay-wrapper iq-main-slider mt-4"
          style={{
            padding: "0px",
            height: "100%",
          }}
        >
          {/* {stream_url && cover_image && (
            <VideoJS
              stream_url={stream_url}
              thumbnail={cover_image}
              stream_id={stream_id}
              thumbnail_sprite={thumbnail_sprite}
              categoryId={category?.id}
              duration={millisecondDuration}
            />
          )} */}
          {stream_url && cover_image && (
            <IframePlayer stream_url={stream_url} />
          )}
        </div>
      </div>
    </div>
  );
};
