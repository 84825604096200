import React from "react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
} from "swiper";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import logo from "assets/images/logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
// import StarRating from "../StarRating/StarRating";
import { animationInit, commaseparated, handleEvent } from "utils/utils";
import TrailerPlayer from "../VideoPlayer/TrailerPlayer/TrailerPlayer";
import "swiper/swiper-bundle.css";
import { useSelector } from "react-redux";
import CompleteRegistrationModal from "views/login/CompleteRegistrationModal";
import { SliderNextButton, SliderPreviousButton } from "ui/Buttons/Buttons";
import TrailerPreviewSection from "components/Common/Slider/TrailerPreviewSection";
import {
  AgeRestriction,
  Duration,
  GenreDetails,
  TagDetails,
} from "components/Common/Slider/Slider";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination, Autoplay]);

const SliderContent = ({ data }) => {
  const [videoUrl, setVideoUrl] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [registrationModal, setRegistrationModal] = React.useState(false);
  const authUser = useSelector((state) => state.auth?.user);
  const playTrailer = (videoUrl) => {
    setVideoUrl(videoUrl);
    setModalShow((prev) => !prev);
  };

  return (
    <section
      id="home"
      className="iq-main-slider p-0 iq-rtl-direction swiper banner-home-swiper overflow-hidden swiper-initialized swiper-horizontal swiper-pointer-events"
    >
      <SliderPreviousButton />
      <SliderNextButton />
      <Swiper
        navigation={{
          prevEl: "#prev5",
          nextEl: "#next5",
        }}
        onInit={() => {
          animationInit();
        }}
        onSlideChangeTransitionStart={() => animationInit()}
        loop={true}
        id="home-slider"
        className="slider m-0 p-0"
        // auto slide after 5 seconds
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {data?.map(
          (
            {
              image,
              content_data: {
                id,
                title,
                trailer,
                tag,
                // release_date,
                // ratings,
                age_restriction,
                description,
                actor,
                genre,
                duration,
              },
              is_games,
            },
            index
          ) => (
            <SwiperSlide
              className="slide slick-bg s-bg-1"
              style={{
                backgroundImage: `url(${image})`,
              }}
              key={index}
            >
              <Container
                fluid
                className="container-fluid position-relative h-100"
              >
                <div className="slider-inner h-100">
                  <Row className="align-items-center  iq-ltr-direction h-100 ">
                    <Col xl="6" lg="7" md="12">
                      <Link to="#" className="d-none d-md-block">
                        <div className="channel-logo" data-iq-delay="0.5">
                          <img src={logo} className="c-logo" alt="streamit" />
                        </div>
                      </Link>
                      <h1
                        className="slider-text big-title title text-uppercase"
                        data-iq-gsap="onStart"
                        data-iq-position-x="-200"
                      >
                        {title}
                      </h1>
                      {!is_games && (
                        <div className="d-flex flex-wrap align-items-center">
                          {/* <div
                          className="slider-ratting d-flex align-items-center mr-4 mt-2 mt-md-3"
                          data-iq-gsap="onStart"
                          data-iq-position-x="-200"
                          data-iq-delay="-0.5"
                        >
                          <StarRating rating={ratings} />
                          <span className="text-white ml-2">
                            {ratings}
                            (lmdb)
                          </span>
                        </div> */}
                          {age_restriction ? (
                            <AgeRestriction age_restriction={age_restriction} />
                          ) : null}
                          {duration ? <Duration duration={duration} /> : null}
                        </div>
                      )}
                      <p
                        className="d-none d-md-block"
                        data-iq-gsap="onStart"
                        data-iq-position-y="80"
                        data-iq-delay="0.8"
                      >
                        {description.length > 50
                          ? description.substring(0, 50) + "..."
                          : description}
                      </p>
                      <div
                        className="trending-list d-none d-md-block"
                        data-wp_object-in="fadeInUp"
                        data-delay-in="1.2"
                      >
                        {actor?.length > 0
                          ? !is_games && (
                              <div className="text-primary title starring">
                                Starring:{" "}
                                <span className="text-body">
                                  {commaseparated(actor, "name")}
                                </span>
                              </div>
                            )
                          : null}
                        {genre && !is_games ? (
                          <GenreDetails genre={genre} />
                        ) : null}
                        {tag ? <TagDetails tag={tag} /> : null}
                      </div>
                      <div className="d-flex align-items-center r-mb-23  mt-4 mt-md-0">
                        <Link
                          to={
                            authUser?.status === 3 && !is_games
                              ? "#"
                              : is_games
                              ? `/game-details/${id}`
                              : `/show-detail/movie/${id}`
                          }
                          className="btn btn-hover iq-button"
                          onClick={() => {
                            handleEvent(
                              "Home Slider",
                              "Clicked on Play Now Button",
                              title
                            );
                            if (authUser?.status !== 3) {
                              return;
                            }
                            setRegistrationModal(true);
                          }}
                        >
                          <i className="fa fa-play mr-2" aria-hidden="true"></i>
                          Play Now
                        </Link>
                      </div>
                    </Col>

                    {trailer && (
                      <TrailerPreviewSection
                        playTrailerFnc={() => playTrailer(trailer)}
                        setRegistrationModal={() => setRegistrationModal(true)}
                        category="Home Slider"
                        label={title}
                      />
                    )}
                  </Row>
                </div>
              </Container>
            </SwiperSlide>
          )
        )}
      </Swiper>

      {modalShow && (
        <TrailerPlayer
          isOpen={modalShow}
          setOpen={() => setModalShow(false)}
          trailer={videoUrl}
        />
      )}

      {registrationModal && (
        <CompleteRegistrationModal
          show={registrationModal}
          onHide={() => setRegistrationModal(false)}
        />
      )}
    </section>
  );
};

export default SliderContent;
