import React, { useEffect, useRef } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { searchAPI } from "features/search/searchAPI";
import { useDispatch, useSelector } from "react-redux";
import { getReleaseDate } from "utils/utils";
import { useOutsideModalClose } from "hooks/useOutsideModalClose";
import CustomToggle from "../dropdowns";
import Card from "../Card";
import UserProfileDropdown from "./UserProfileDropdown";
import CirclesLoader from "ui/Circles/CirclesLoader";
import searchIcon from "assets/images/search.svg";

const NavbarRight = () => {
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const dropdownRef = useRef(null);
  const searchedList = useSelector((state) => state.search.searchedList);
  const { image, status } = useSelector((state) => state.auth.user);
  // const [checked, setChecked] = React.useState(true);
  const dispatch = useDispatch();
  useOutsideModalClose(".searchbox");

  useEffect(() => {
    if (search) {
      const timeoutId = setTimeout(() => {
        setLoading(true);
        dispatch(
          searchAPI.endpoints.search.initiate(search, {
            forceRefetch: true,
          })
        ).then(({ data }) => {
          setLoading(false);
        });
      }, 400); // delay of 400ms

      return () => clearTimeout(timeoutId);
    }
  }, [search, dispatch]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    const closeSearch = () => {
      setSearch("");
    };

    document.addEventListener("click", closeSearch);
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("click", closeSearch);
  }, []);

  return (
    <div className="navbar-right menu-right">
      <ul className="d-flex align-items-center list-inline m-0">
        {/* <Dropdown as="li" className="nav-item language mr-2 nav-icon">
          <div className="switch">
            <input
              id="language-toggle-1"
              className="check-toggle check-toggle-round-flat"
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label htmlFor="language-toggle-1"></label>
            <span
              className="on"
              onClick={() => {
                console.log("bangla");
              }}
            >
              {" "}
              বাং{" "}
            </span>
            <span
              className="off"
              onClick={() => {
                console.log("english");
              }}
            >
              {" "}
              EN{" "}
            </span>
          </div>
        </Dropdown> */}
        {status !== 3 && (
          <Dropdown as="li" className="nav-item language mr-2">
            <Dropdown.Toggle
              as={CustomToggle}
              href="#"
              variant="search-toggle device-search"
            >
              <i
                className="ri-search-line"
                title="Search for movies, tv shows, and more..."
              ></i>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="search-box iq-search-bar d-search p-0 m-0"
              align="right"
            >
              {
                <Form className="searchbox">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="text search-input font-size-12"
                      required
                      placeholder="Type here to search..."
                      onChange={handleInputChange}
                      value={search}
                    />
                    <img src={searchIcon} alt="" />
                  </div>
                </Form>
              }
              <Dropdown.Menu
                className="iq-sub-dropdown searched-list"
                align="right"
                style={{
                  ...(!searchedList.length &&
                    !search && {
                      border: "none",
                    }),
                }}
              >
                <Card className="shadow-none m-0">
                  <Card.Body>
                    {loading ? (
                      <>
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            height: "200px",
                          }}
                        >
                          <CirclesLoader />
                        </div>
                      </>
                    ) : (
                      search &&
                      searchedList?.map(
                        ({ id, thumbnail, title, release_date }, index) => {
                          return (
                            <Link
                              to={`/show-detail/movie/${id}`}
                              className="iq-sub-card"
                              key={index}
                              onClick={() => {
                                setShowDropdown(false);
                                setSearch("");

                                const customToggle =
                                  document.querySelector(".device-search");
                                customToggle.click();
                              }}
                            >
                              <div className="media align-items-center">
                                <img
                                  style={{
                                    width: "64px",
                                    height: "64px",
                                  }}
                                  src={thumbnail}
                                  className="img-fluid mr-3"
                                  alt="streamit"
                                />
                                <div className="media-body">
                                  <h6 className="mb-0 ">{title}</h6>
                                  {release_date && (
                                    <small className="font-size-12">
                                      {getReleaseDate(release_date)}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </Link>
                          );
                        }
                      )
                    )}
                  </Card.Body>
                </Card>
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Dropdown as="li" className="nav-item nav-icon">
          <Link
            to="/leaderboard"
            className="nav-link nav-link-lg"
            title="Leaderboard"
          >
            <i className="ri-trophy-line"></i>
          </Link>
        </Dropdown>

        <Dropdown
          as="li"
          className="nav-item nav-icon"
          show={showDropdown}
          onClick={() => setShowDropdown(!showDropdown)}
          ref={dropdownRef}
        >
          <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle">
            <div
              className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
              data-toggle="search-toggle"
            >
              <img
                src={image}
                className="img-fluid avatar-40 rounded-circle"
                alt="user"
                title="Profile"
              />
            </div>
          </Dropdown.Toggle>
          <UserProfileDropdown />
        </Dropdown>
      </ul>
    </div>
  );
};

export default NavbarRight;
