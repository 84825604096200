import { apiSlice } from "../api/apiSlice";
import { SITE_DATA } from "../../service/apiEndPoints";
import { handleAllError } from "../../utils/utils";

export const siteDataAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSiteData: builder.query({
            query: () => SITE_DATA,
            async onQueryStarted(arg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    handleAllError(data);
                } catch (err) {
                    // do nothing
                }
            },
        }),

    }),
});

export const { useGetSiteDataQuery } = siteDataAPI;