import React from "react";
import { Circles } from "react-loader-spinner";
const CirclesLoader = ({ width = 50, height = 30, color = "#4fa94d" }) => {
  return (
    <Circles
      height={height}
      width={width}
      radius="9"
      color={color}
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName="three-dots-loading"
      visible={true}
    />
  );
};

export default CirclesLoader;
