import React, { useEffect } from "react";
// import StarRating from "../StarRating/StarRating";
import { durationFormat, getReleaseDate, handleEvent } from "utils/utils";
import { useAddToFavouriteMutation } from "features/home/movies/moviesApi";
import { Link } from "react-router-dom";
// import { ModalVideoReact } from '../ModalVideo/ModalVideoReact';
import { useDispatch } from "react-redux";
import { contentDetail } from "features/contentDetail/contentDetailSlice";
import TrailerPlayer from "../VideoPlayer/TrailerPlayer/TrailerPlayer";

export const MovieTrailerSection = (props) => {
  const {
    data: {
      id,
      content_id,
      title,
      // ratings,
      release_date,
      genre = [],
      trailer,
      total_views,
      tag,
      duration,
      total_favourite,
      user_favourite,
      cover_image,
    },
  } = props;
  const [addToFavourite, { isError }] = useAddToFavouriteMutation();
  const [trailerOpen, setTrailerOpen] = React.useState(false);
  const dispatch = useDispatch();
  const detailData = {
    ...props.data,
    user_favourite: !user_favourite,
    total_favourite: user_favourite ? total_favourite - 1 : total_favourite + 1,
  };

  useEffect(() => {
    if (isError) {
      dispatch(contentDetail(detailData));
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleAddToFavourite = async (content_id, uuid) => {
    dispatch(contentDetail(detailData));
    await addToFavourite({ content_id, uuid });
  };

  return (
    <div className="trending-info mt-4 pt-0 pb-4">
      <div className="row">
        <div className="col-md-9 col-12  mb-auto">
          <div className="d-md-flex">
            <h3
              className="trending-text big-title text-uppercase mt-0 fadeInLeft animated"
              data-animation-in="fadeInLeft"
              data-delay-in="0.6"
              style={{
                opacity: 1,
                animationDelay: "0.6s",
              }}
            >
              {title}
            </h3>
            {/* <div className="slider-ratting d-flex align-items-center ml-md-3 ml-0">
              <StarRating rating={ratings} />
              <span className="text-white ml-2">{ratings} (lmdb)</span>
            </div> */}
          </div>
          <ul className="p-0 mt-2 list-inline d-flex flex-wrap movie-content">
            {genre.length
              ? genre.map((data, key) => (
                  <li className="trending-list" key={key}>
                    <span className="text-primary title">{data.title}</span>
                  </li>
                ))
              : null}
          </ul>
          <div className="d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4 movie__trailer__section">
            <span className="badge badge-secondary font-size-16">G</span>
            <span className="ml-3 font-Weight-500 genres-info">
              {durationFormat(duration)}
            </span>
            <span className="trending-year trending-year-list font-Weight-500 genres-info">
              {getReleaseDate(release_date)}
            </span>
            <span className="trending-year trending-year-list single-view-count font-Weight-500 genres-info">
              <i className="fa fa-eye"></i> {total_views} views
            </span>
          </div>
          <ul className="p-0 list-inline d-flex flex-wrap align-items-center mb-3 mt-4 iq_tag-list">
            <li className="text-primary text-lable mr-3">
              <i className="fa fa-tags" aria-hidden="true"></i>Tags:
            </li>
            <li className="trending-list mr-3">{tag}</li>
          </ul>
          <ul className="list-inline p-0 m-0 share-icons music-play-lists mb-1">
            <li>
              <span
                style={{
                  background: user_favourite ? "#e50914" : "#fff",
                }}
                className={` count_bg`}
                onClick={() => {
                  handleEvent(
                    "Movie Details",
                    "Clicked on Favourite Icon Button"
                  );
                  handleAddToFavourite(id, content_id);
                }}
              >
                <i
                  className={`ri-heart-fill`}
                  style={{
                    color: user_favourite ? "#fff" : "#e50914",
                  }}
                ></i>
              </span>
              {total_favourite ? (
                <span className={`count-box`}>{total_favourite}+</span>
              ) : null}
            </li>
          </ul>
        </div>
        <div className="trailor-video col-md-3 col-12 mt-lg-0 mt-4 mb-md-0 mb-1 text-lg-right">
          <Link
            to={"#"}
            style={{
              cursor: trailer ? "pointer" : "context-menu",
            }}
            onClick={() => {
              handleEvent("Movie Details", "Clicked on Trailer Link Button");
              setTrailerOpen((prev) => {
                if (trailer) {
                  return !prev;
                } else {
                  return prev;
                }
              });
            }}
            className="video-open playbtn block-images position-relative playbtn_thumbnail"
          >
            <img
              width="1920"
              height="1080"
              src={cover_image}
              className="attachment-medium-large size-medium-large wp-post-image"
              alt=""
              loading="lazy"
              sizes="(min-width: 960px) 75vw, 100vw"
            />
            {trailer && (
              <span className="content btn btn-transparant iq-button">
                <i className="fa fa-play mr-2"></i>
                <span>Trailer Link</span>
              </span>
            )}
          </Link>
        </div>
      </div>
      {trailerOpen && (
        <TrailerPlayer
          trailer={trailer}
          thumbnail={cover_image}
          isOpen={trailerOpen}
          setOpen={setTrailerOpen}
        />
      )}
    </div>
  );
};
