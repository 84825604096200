import React from "react";

const CustomLoader = () => {
  return (
    <div id="loading">
      <div id="loading-center"></div>
    </div>
  );
};

export default CustomLoader;
