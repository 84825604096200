import React from 'react'
import StarRating from '../StarRating/StarRating';

const Reviews = ({ reviews = [] }) => {
    // What to do render
    let content = null;
    if (reviews && reviews.length > 0) {
        content = reviews.map(({ user: { image, name }, review, rating }, index) => (
            <div className="media mt-2" key={index}>
                <img style={{
                    width: '50px',
                    height: '50px',
                }} className="mr-3" src={image} alt="" />
                <div className="media-body">
                    <div className='d-flex'>
                        <h5 className="mt-0 mr-2">{name}</h5>
                        <StarRating rating={rating} />
                    </div>
                    {review}
                </div>
            </div>
        ))
    } else {
        content = <p className="masvideos-noreviews mt-3">
            There are no reviews yet.
        </p>
    }
    return (content)
}

export default Reviews;