import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "assets/images/logo.png";
import ThreeDotToggle from "./ThreeDotToggle";
import NavbarRight from "./NavbarRight";
import NavbarItems from "./NavbarItems";
import BurgerMenu from "./BurgerMenu";

const HeaderStyle = () => {
  const { isReactModal } = useSelector((state) => state.isReactModal);
  return (
    <>
      <header
        id="main-header"
        style={{
          zIndex: isReactModal ? 0 : 9999,
        }}
      >
        <div className="main-header">
          <Container fluid>
            <Row>
              <Col sm="12">
                <Navbar expand="lg" className="p-0">
                  <BurgerMenu />
                  <Link className="navbar-brand" to={"/"}>
                    <img className="img-fluid logo" src={logo} alt="streamit" />
                    <span
                      className="text-white text-uppercase logo-text"
                      // style={{
                      //   fontSize: "1.25rem",
                      // }}
                    >
                      {" "}
                      Breakstation TV
                    </span>
                  </Link>
                  <ThreeDotToggle />
                  <NavbarItems />
                  <NavbarRight />
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderStyle;
