import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const GuestRoute = (props) => {
  const auth = useSelector((state) => state.auth);
  if (auth?.accessToken && auth?.user) {
    return <Navigate to={"/"} replace />;
  } else {
    return <Outlet />;
  }
};

export default GuestRoute;
