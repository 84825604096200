import React from "react";
import { commaseparated, durationFormat } from "utils/utils";

export const AgeRestriction = ({ age_restriction }) => {
  return (
    <div
      className="d-flex align-items-center mt-2 mt-md-3"
      data-iq-gsap="onStart"
      data-iq-position-x="-200"
      data-iq-delay="-0.5"
    >
      <span className="badge badge-secondary p-2">{age_restriction}+</span>
    </div>
  );
};

export const Duration = ({ duration }) => {
  return (
    <div className="d-flex align-items-center mt-2 mt-md-3">
      <span className="ml-3">{durationFormat(duration)}</span>
    </div>
  );
};

export const GenreDetails = ({ genre }) => {
  return (
    <div className="text-primary title genres">
      Genres:{" "}
      <span className="text-body">{commaseparated(genre, "title")}</span>
    </div>
  );
};

export const TagDetails = ({ tag }) => {
  return (
    <div className="text-primary title tag">
      Tag: <span className="text-body">{commaseparated(tag, "title")}</span>
    </div>
  );
};
