import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSliderQuery } from "features/slider/sliderApi";
import { GAMES_LIST } from "service/apiEndPoints";
import { useSelector } from "react-redux";
import { handleEvent } from "utils/utils";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import DataNotFound from "ui/DataNotFound/DataNotFound";
import CustomLoader from "ui/Loader/CustomLoader";
import CompleteRegistrationModal from "views/login/CompleteRegistrationModal";
import "swiper/swiper-bundle.css";
import GameList from "components/Games/GameList";
// import StarRating from "components/StarRating/StarRating";

SwiperCore.use([Navigation, Autoplay]);

const Games = () => {
  const { data, isLoading } = useSliderQuery(GAMES_LIST);
  const authUser = useSelector((state) => state.auth?.user);
  const [registrationModal, setRegistrationModal] = React.useState(false);

  if (isLoading) return <CustomLoader />;

  return (
    <>
      {data?.data?.length > 0 ? (
        <section id="movieshow" className="iq-main-slider p-0">
          <div
            id="prev"
            className="swiper-button swiper-button-prev main-carousel-left-arrow"
          >
            <i className="ri-arrow-left-s-line arrow-icon"></i>
          </div>
          <div
            id="next"
            className="swiper-button swiper-button-next main-carousel-right-arrow"
          >
            <i className="ri-arrow-right-s-line arrow-icon"></i>
          </div>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={0}
            centeredSlides={true}
            navigation={{
              prevEl: "#prev",
              nextEl: "#next",
            }}
            loop={true}
            className="movie-category-slide"
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {data?.data?.map(
              (
                { id, image, thumbnail, cover_image, name, description, genre },
                index
              ) => (
                <SwiperSlide key={index}>
                  <div className="shows-img">
                    <img src={cover_image} className="w-100 img" alt="" />
                    <div className="shows-content h-100">
                      <div className="row align-items-center h-100">
                        <div className="col-lg-7 col-md-12">
                          <h1
                            className="slider-text big-title title text-uppercase"
                            data-animation-in="fadeInLeft"
                            data-delay-in="0.6"
                          >
                            {name}
                          </h1>
                          <div
                            className="flex-wrap align-items-center fadeInLeft animated"
                            data-animation-in="fadeInLeft"
                            style={{
                              opacity: 1,
                            }}
                          >
                            <p
                              className="movie-banner-text"
                              data-animation-in="fadeInUp"
                              data-delay-in="1.2"
                            >
                              {description}
                            </p>
                          </div>
                          <div
                            className="d-flex align-items-center r-mb-23"
                            data-animation-in="fadeInUp"
                            data-delay-in="1.2"
                          >
                            <Link
                              to={
                                authUser?.status === 3
                                  ? "#"
                                  : `/game-details/${id}`
                              }
                              className="btn btn-hover iq-button"
                              onClick={() => {
                                handleEvent(
                                  "Game",
                                  "Clicked on Play Now Button",
                                  name
                                );

                                if (authUser?.status !== 3) {
                                  return;
                                }
                                setRegistrationModal(true);
                              }}
                            >
                              <i
                                className="fa fa-play mr-2"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </section>
      ) : (
        <DataNotFound type="slider" />
      )}
      <div
        className="main-content"
        style={{
          paddingTop: data?.data?.length > 0 ? "40px" : "0px",
        }}
      >
        <GameList
          label="Games"
          id="iq-favorites"
          endpoint={GAMES_LIST}
          type="natok"
          path="/game-details"
        />
      </div>

      {registrationModal && (
        <CompleteRegistrationModal
          show={registrationModal}
          onHide={() => setRegistrationModal(false)}
        />
      )}
    </>
  );
};
export default Games;
