import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../features/api/apiSlice';
import authReducer from '../features/auth/authSlice';
import Mode from '../features/mode/rtlmode';
import contentDetailReducer from '../features/contentDetail/contentDetailSlice';
import searchReducer from '../features/search/searchedSlice';
import { homeSlider } from '../features/slider/sliderSlice';
import modalVideoPlayerSlice from '../features/videoPlayerSlice/modalVideoPlayerSlice';


export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        slider: homeSlider,
        contentDetail: contentDetailReducer,
        search: searchReducer,
        mode: Mode,
        isReactModal: modalVideoPlayerSlice,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddlewares) =>
        getDefaultMiddlewares().concat(apiSlice.middleware),
});