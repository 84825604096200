import { apiSlice } from "../api/apiSlice";
import {
  GAMES_LIST,
  GAMES_URL,
  GAMES_STATUS,
} from "../../service/apiEndPoints";
import { handleAllError } from "../../utils/utils";

export const gamesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    gameList: builder.query({
      query: () => GAMES_LIST,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    game: builder.query({
      query: (game_id) => GAMES_URL + game_id,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    gameStatus: builder.query({
      query: (session_id) => {
        console.log(session_id);
        return {
          url: GAMES_STATUS,
          body: session_id,
        };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
      // Don't cache this query
      providesTags: (result, error, session_id) =>
        session_id ? [{ type: "GameStatus", id: session_id }] : [],
    }),
  }),
});

export const { useGameListQuery, useGameQuery, useGameStatusQuery } = gamesApi;
