import React, { useState } from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import logo from "assets/images/logo.png";
import {
  commaseparated,
  durationFormat,
  getReleaseDate,
  getReleaseYear,
  handleEvent,
} from "utils/utils";
import TvSeriesModal from "../VideoPlayer/TvSeries/TvSeriesModal";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);
const TrendingSection2 = ({ data, viewAll, path }) => {
  // eslint-disable-next-line
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(data ? [data[0]] : []);
  const [modalShow, setModalShow] = useState(false);
  const [tvSeriesData, setTvSeriesData] = useState({});

  const getLatestEpisode = (seasons) => {
    if (seasons?.length > 0) {
      if (seasons[seasons?.length - 1]?.episodes?.length > 0) {
        return seasons[seasons?.length - 1]?.episodes;
      }
    }
  };

  return (
    <section id="iq-trending" className="s-margin">
      {data?.length > 0 && (
        <Container fluid>
          <Row>
            <Col sm="12" className="overflow-hidden">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="main-title">Trending</h4>
                {viewAll && data.length > 0 && (
                  <Link className="iq-view-all" to={path}>
                    View All
                  </Link>
                )}
              </div>
              <div id="trending-contens">
                <Swiper
                  as="ul"
                  thumbs={{ swiper: thumbsSwiper }}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  navigation={{
                    prevEl: "#prev4",
                    nextEl: "#next4",
                  }}
                  slidesPerView={5}
                  spaceBetween={20}
                  breakpoints={{
                    320: { slidesPerView: 3 },
                    550: { slidesPerView: 3 },
                    991: { slidesPerView: 4 },
                    1400: { slidesPerView: 4 },
                    1500: { slidesPerView: 5 },
                  }}
                  loop={true}
                  slideToClickedSlide={true}
                  className="list-inline p-0 m-0 row align-items-center iq-rtl-direction"
                  onActiveIndexChange={(swiper) => {
                    // swiper.realIndex
                    setCurrentSlide([data[swiper.realIndex]]);
                  }}
                >
                  {data?.map((item, index) => (
                    <SwiperSlide
                      as="li"
                      key={index}
                      onClick={() => setCurrentSlide([item])}
                      style={{
                        marginTop: index !== 0 ? "25px" : "0px",
                      }}
                    >
                      <Link to="#">
                        <div className="movie-slick position-relative">
                          <img
                            src={item?.cover_image}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div id="trending__main_slider">
                <Swiper
                  slidesPerView={1}
                  id="trending-slider"
                  loop={true}
                  allowTouchMove={false}
                  className="mt-3  list-inline p-0 m-0  d-flex align-items-center iq-rtl-direction"
                >
                  {currentSlide?.map(
                    (
                      {
                        id,
                        cover_image,
                        title,
                        age_restriction,
                        seasons,
                        release_date,
                        description,
                        actor,
                        genre,
                        trailer,
                        thumbnail,
                        category: { id: categoryId },
                      },
                      index
                    ) => {
                      const latestEpisode = getLatestEpisode(seasons);
                      return (
                        <SwiperSlide as="li" key={index}>
                          <div
                            className="tranding-block position-relative"
                            style={{ backgroundImage: `url(${cover_image})` }}
                          >
                            <Tab.Container
                              defaultActiveKey="trending-data1"
                              className="trending-custom-tab"
                            >
                              <div className="tab-title-info position-relative iq-ltr-direction">
                                <Nav
                                  as="ul"
                                  variant="pills"
                                  className="trending-pills nav-pills d-flex justify-content-center align-items-center text-center iq-ltr-direction"
                                >
                                  <Nav.Item as="li" className="nav-item">
                                    <Nav.Link href="" eventKey="trending-data1">
                                      Overview
                                    </Nav.Link>
                                  </Nav.Item>
                                  {categoryId === 2 && latestEpisode && (
                                    <Nav.Item as="li" className="nav-item">
                                      <Nav.Link
                                        href=""
                                        eventKey="trending-data2"
                                      >
                                        Episodes
                                      </Nav.Link>
                                    </Nav.Item>
                                  )}

                                  {trailer && (
                                    <Nav.Item as="li" className="nav-item">
                                      <Nav.Link
                                        href=""
                                        eventKey="trending-data3"
                                      >
                                        Trailers
                                      </Nav.Link>
                                    </Nav.Item>
                                  )}
                                </Nav>
                              </div>
                              <Tab.Content className="trending-content">
                                <Tab.Pane
                                  eventKey="trending-data1"
                                  className="overlay-tab show fade"
                                >
                                  <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                    <Link to="#" tabIndex="0">
                                      <div className="res-logo">
                                        <div className="channel-logo">
                                          <img
                                            src={logo}
                                            className="c-logo"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </Link>
                                    <h1 className="trending-text big-title text-uppercase">
                                      {title}
                                    </h1>
                                    <div className="d-flex align-items-center text-white text-detail">
                                      <span className="badge badge-secondary p-3">
                                        {age_restriction}+
                                      </span>
                                      {seasons?.length > 0 && (
                                        <span className="ml-3">
                                          {seasons?.length} Seasons
                                        </span>
                                      )}
                                      <span className="trending-year">
                                        {getReleaseYear(release_date)}
                                      </span>
                                    </div>
                                    <p className="trending-dec">
                                      {description}
                                    </p>
                                    <div className="text-left">
                                      <div className="d-flex align-items-center p-0">
                                        <Link
                                          to={`/show-detail/movie/${id}`}
                                          className="btn btn-hover mr-2"
                                          tabIndex="0"
                                          onClick={() => {
                                            handleEvent(
                                              "Trending",
                                              "Clicked on Play Now Button",
                                              title
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-play mr-2"
                                            aria-hidden="true"
                                          ></i>
                                          Play Now
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="trending-list mt-4">
                                      {actor?.length > 0 ? (
                                        <div className="text-primary title">
                                          Starring:
                                          <span className="text-body">
                                            {commaseparated(actor, "name")}
                                          </span>
                                        </div>
                                      ) : null}

                                      {genre?.length > 0 && (
                                        <div className="text-primary title">
                                          Genres:
                                          <span className="text-body">
                                            {commaseparated(genre, "title")}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Tab.Pane>
                                {latestEpisode ? (
                                  <Tab.Pane
                                    eventKey="trending-data2"
                                    className="overlay-tab  fade show "
                                    id="trending-episode1"
                                  >
                                    <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                      <Link to="#" tabIndex="0">
                                        <div className="channel-logo">
                                          <img
                                            src={logo}
                                            className="c-logo"
                                            alt="stramit"
                                          />
                                        </div>
                                      </Link>
                                      <h1 className="trending-text big-title text-uppercase">
                                        {title}
                                      </h1>
                                      <div className="d-flex align-items-center text-white text-detail mb-4">
                                        {seasons?.length > 0 && (
                                          <span className="season_date ml-2">
                                            {seasons?.length} Seasons
                                          </span>
                                        )}
                                        <span className="trending-year">
                                          {getReleaseDate(release_date)}
                                        </span>
                                      </div>
                                      <div
                                        className="episodes-contens mt-4"
                                        id="episode1"
                                      >
                                        <Swiper
                                          spaceBetween={20}
                                          breakpoints={{
                                            320: { slidesPerView: 1 },
                                            550: { slidesPerView: 2 },
                                            991: { slidesPerView: 3 },
                                            1400: { slidesPerView: 4 },
                                          }}
                                          loop={true}
                                          className="list-inline p-0 m-0 iq-rtl-direction"
                                        >
                                          {latestEpisode?.map(
                                            (
                                              {
                                                title,
                                                thumbnail,
                                                description,
                                                duration,
                                                stream_url,
                                                stream_id,
                                                thumbnail_sprite,
                                              },
                                              key
                                            ) => (
                                              <SwiperSlide
                                                className="e-item"
                                                key={key}
                                                style={{
                                                  width:
                                                    window.innerWidth > 1400
                                                      ? "25%"
                                                      : window.innerWidth > 991
                                                      ? "33.33%"
                                                      : window.innerWidth > 550
                                                      ? "50%"
                                                      : "100%",
                                                  height: "auto",
                                                  marginRight: "20px",
                                                  cursor: "grab",
                                                }}
                                              >
                                                <div className="block-image position-relative">
                                                  <Link to="#">
                                                    <img
                                                      src={cover_image}
                                                      className="img-fluid"
                                                      alt=""
                                                    />
                                                  </Link>
                                                  <div className="episode-play-info">
                                                    <div className="episode-play">
                                                      <Link
                                                        to="#"
                                                        onClick={() => {
                                                          handleEvent(
                                                            "Trending",
                                                            "Clicked on Play Button",
                                                            title
                                                          );
                                                          setModalShow(
                                                            (prev) => !prev
                                                          );
                                                          setTvSeriesData({
                                                            stream_url,
                                                            thumbnail,
                                                            stream_id,
                                                            duration,
                                                            thumbnail_sprite,
                                                          });
                                                        }}
                                                        tabIndex="0"
                                                      >
                                                        <i className="ri-play-fill"></i>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="episodes-description text-body">
                                                  <div className="d-flex align-items-center justify-content-between">
                                                    <Link to="#">{title}</Link>
                                                    <span className="text-primary">
                                                      {durationFormat(duration)}
                                                    </span>
                                                  </div>
                                                  <p className="mb-0">
                                                    {description}
                                                  </p>
                                                </div>
                                              </SwiperSlide>
                                            )
                                          )}
                                        </Swiper>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                ) : null}

                                {trailer ? (
                                  <Tab.Pane
                                    eventKey="trending-data3"
                                    className="overlay-tab fade show"
                                  >
                                    <div className="trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction">
                                      <video src={trailer} controls></video>
                                    </div>
                                  </Tab.Pane>
                                ) : null}
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              </div>
            </Col>
          </Row>

          {modalShow && (
            <TvSeriesModal
              {...tvSeriesData}
              open={modalShow}
              setOpen={setModalShow}
            />
          )}
        </Container>
      )}
    </section>
  );
};

export default TrendingSection2;
