import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const CompleteRegistrationModal = (props) => {
  const authUser = useSelector((state) => state.auth?.user);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton className="game-win-lose-modal-header">
      </Modal.Header>
      <Modal.Body className="text-center">
        <h4 className="text-dark mb-2">Complete your registration!</h4>
        <p className="text-dark">
          Please complete your profile to access all contents.
        </p>
      </Modal.Body>
      {authUser?.status !== 1 && (
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <Link to="/manage-profile" className="btn btn-hover" data-dismiss="modal">
            Okay
          </Link>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CompleteRegistrationModal;
