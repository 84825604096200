import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import GuestRoute from "./GuestRoute";
import MasterLayout from "../layouts/masterLayout";
import urls from "../enums/urls";
import Login from "../views/login/login";
// import { useSelector } from "react-redux";

function AppRoutes() {
  const permissionList = [];
  // const authUser = useSelector((state) => state.auth.user);
  // const notMadlyUserUrls = [
  //   "HOME",
  //   "GAMES",
  //   "GAME_DETAILS",
  //   "MANAGE_PROFILE",
  //   "LEADERBOARD",
  // ];

  // const newData = Object.keys(urls)
  //   .filter((key) => notMadlyUserUrls?.includes(key))
  //   .reduce((obj, key) => {
  //     obj[key] = urls[key];
  //     return obj;
  //   }, {});

  // const modifiedUrls = authUser?.status !== 3 ? urls : newData;
  return (
    <Routes>
      <Route element={<GuestRoute />}>
        <Route path="/sign-in" element={<Login />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route element={<MasterLayout />}>
          {Object.values(urls).map((r, key) => {
            return (
              <Route
                key={key}
                exact
                path={r.url}
                element={
                  r.permission ? (
                    permissionList?.includes(r.permission) ? (
                      r.component
                    ) : (
                      <div>Unauthorized</div>
                    )
                  ) : (
                    r.component
                  )
                }
              />
            );
          })}
        </Route>
      </Route>

      <Route
        path="*"
        // element={
        //     props.user ?
        //         <MasterLayout>
        //             <NotFound />
        //         </MasterLayout>
        //         : <NotFound
        //         />
        // }
        element={
          <div>
            <h1>Not Found, 404</h1>
          </div>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
