import GameDetails from "components/Games/GameDetails";
import Unauthorized from "error/unauthorize";
import BanglaNatok from "views/banglaNatok/banglaNatok";
import FAQ from "views/faq/faq";
import Games from "views/games/games";
import Homepage from "views/home/home";
import LeaderBoard from "views/leaderBoard/leaderBoard";
import Login from "views/login/login";
import MovieCategory from "views/movieCategory/movieCategory";
import PrivacyPolicy from "views/privacyPolicy/privacyPolicy";
import TermsOfUse from "views/termsOfUse/termsOfUse";
import TvShow from "views/tvShows/tvShow";
import UserProfile from "views/userProfile/userprofile";
import MovieDetails from "views/movieDetails/movieDetails";
import ShowDetail from "views/showDetail/showDetail";
import ViewAll from "views/viewAll/viewAll";
// import { StarringInfo } from "views/starringInfo/starringInfo";
// import Contact from "views/contact/contact";
// import AboutUs from "views/aboutUs/AboutUs";
// import AccountSeeting from "views/accountSettings/accountSetting";
// import Blog from "views/blog/blog";
// import BlogDetail from "views/blogDetails/blog-details";
// import { GenresDetails } from "views/genresDetails/genresDetails";
// import MusicVideos from "views/musicVideos/musicVideos";
// import PricingPlan from "views/pricingPlan/pricing";

// eslint-disable-next-line
export default {
  HOME: {
    url: "/",
    permission: null,
    component: <Homepage />,
  },
  SIGN_IN: {
    url: "/sign-in",
    permission: null,
    component: <Login />,
  },
  UN_AUTHORIZED: {
    url: "/unauthorized",
    permission: null,
    component: <Unauthorized />,
  },
  MOVIE_CATEGORY: {
    url: "/movies",
    permission: null,
    component: <MovieCategory />,
  },
  TV_SERIES_CATEGORY: {
    url: "/tv-series",
    permission: null,
    component: <TvShow />,
  },
  // MUSIC_VIDEOS: {
  //   url: "/music-video",
  //   permission: null,
  //   component: <MusicVideos />,
  // },
  BANGLA_NATOK: {
    url: "/bangla-natok",
    permission: null,
    component: <BanglaNatok />,
  },
  SHOW_DETAILS: {
    url: "/show-detail",
    permission: null,
    component: <ShowDetail />,
  },
  SHOW_MOVIE_DETAIL: {
    url: "/show-detail/movie/:id",
    permission: null,
    component: <ShowDetail />,
  },
  VIEW_ALL: {
    url: "/view-all/:type",
    permission: null,
    component: <ViewAll />,
  },
  // VIDEO_DETAILS: {
  //     url: "/video-details",
  //     permission: null,
  //     component: <GenresDetails />
  // },
  // STARRING_DETAILS: {
  //   url: "/starring-details",
  //   permission: null,
  //   component: <StarringInfo />,
  // },
  MOVIE_DETAILS: {
    url: "/movie-details",
    permission: null,
    component: <MovieDetails />,
  },
  FAQ: {
    url: "/faq",
    permission: null,
    component: <FAQ />,
  },
  // ABOUT_US: {
  //   url: "/about-us",
  //   permission: null,
  //   component: <AboutUs />,
  // },
  // CONTACT: {
  //   url: "/contact",
  //   permission: null,
  //   component: <Contact />,
  // },
  TERMS_OF_SERVICE: {
    url: "/terms-of-use",
    permission: null,
    component: <TermsOfUse />,
  },
  PRIVACY_POLICY: {
    url: "/privacy-policy",
    permission: null,
    component: <PrivacyPolicy />,
  },
  MANAGE_PROFILE: {
    url: "/manage-profile",
    permission: null,
    component: <UserProfile />,
  },
  // ACCOUNT_SETTING: {
  //   url: "/setting",
  //   permission: null,
  //   component: <AccountSeeting />,
  // },
  // PRICING_PLAN: {
  //   url: "/pricing-plan",
  //   permission: null,
  //   component: <PricingPlan />,
  // },
  // BLOG: {
  //   url: "/blog",
  //   permission: null,
  //   component: <Blog />,
  // },
  // BLOG_DETAILS: {
  //   url: "/blog-details",
  //   permission: null,
  //   component: <BlogDetail />,
  // },
  LEADERBOARD: {
    url: "/leaderboard",
    permission: null,
    component: <LeaderBoard />,
  },
  GAMES: {
    url: "/games",
    permission: null,
    component: <Games />,
  },
  GAME_DETAILS: {
    url: "/game-details/:id",
    permission: null,
    component: <GameDetails />,
  },
  GAME_OVER: {
    url: "/game-over/:id",
    permission: null,
    component: <GameDetails />,
  },
};
