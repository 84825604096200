import React from "react";
import { Link } from "react-router-dom";
import { handleEvent } from "utils/utils";

export const PreviousButton = ({ id }) => {
  return (
    <div id={`next1-${id}`} className="swiper-button swiper-button-prev">
      <i className="fa fa-chevron-left"></i>
    </div>
  );
};

export const NextButton = ({ id }) => {
  return (
    <div id={`prev1-${id}`} className="swiper-button swiper-button-next">
      <i className="fa fa-chevron-right"></i>
    </div>
  );
};

export const SliderPreviousButton = ({ id = "prev5" }) => {
  return (
    <div
      id={id}
      className="swiper-button swiper-button-prev main-carousel-left-arrow"
    >
      <i className="ri-arrow-left-s-line arrow-icon"></i>
    </div>
  );
};

export const SliderNextButton = ({ id = "next5" }) => {
  return (
    <div
      id={id}
      className="swiper-button swiper-button-next main-carousel-right-arrow"
    >
      <i className="ri-arrow-right-s-line arrow-icon"></i>
    </div>
  );
};

export const PlayButton = ({
  path = "",
  label = "",
  name = "",
  onClick = () => handleEvent(label, "Clicked on Play Now Button", name),
  is_upcoming = false,
}) => {
  return (
    <div className="hover-buttons">
      <Link
        to={path}
        role="button"
        className="btn btn-hover"
        onClick={onClick}
        style={{
          cursor: is_upcoming ? "not-allowed" : "pointer",
        }}
        title={is_upcoming ? "Coming Soon" : "Play Now"}
      >
        <i className="fa fa-play mr-1" aria-hidden="true"></i>
        Play Now
      </Link>
    </div>
  );
};

export const WatchTrailerButton = ({ title = "Watch Trailer" }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="80px"
        height="80px"
        viewBox="0 0 213.7 213.7"
        enableBackground="new 0 0 213.7 213.7"
        xmlSpace="preserve"
      >
        <polygon
          className="triangle"
          fill="none"
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="73.5,62.5 148.5,105.8 73.5,149.1 "
        />
        <circle
          className="circle"
          fill="none"
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          cx="106.8"
          cy="106.8"
          r="103.3"
        />
      </svg>
      <span className="w-trailor">{title}</span>
    </>
  );
};

export const FavouriteButtonAndCount = ({ item = {}, onClick = () => {} }) => {
  const { content_id, total_favourite, user_favourite } = item || {};
  return (
    <div className="block-social-info">
      <ul className="list-inline p-0 m-0 music-play-lists">
        <li>
          <span
            style={{
              background: user_favourite ? "#e50914" : "#fff",
            }}
            className={`count_bg_${content_id} count_bg`}
            onClick={onClick}
          >
            <i
              className={`ri-heart-fill count_heart_icon_${content_id}`}
              style={{
                color: user_favourite ? "#fff" : "#e50914",
              }}
            ></i>
          </span>
          {total_favourite ? (
            <span className={`count-box count_${content_id}`}>
              {total_favourite}+
            </span>
          ) : null}
        </li>
      </ul>
    </div>
  );
};
