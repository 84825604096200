import { handleAllError } from "../../utils/utils";
import { apiSlice } from "../api/apiSlice";

export const slider = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        slider: builder.query({
            query: (endpoint) => endpoint,
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    handleAllError(data);
                } catch (err) {
                    // do nothing
                }
            },
        })
    }),
});

export const { useSliderQuery } = slider;