import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contentDetail: {}
};

const contentDetailSlice = createSlice({
    name: "contentDetail",
    initialState,
    reducers: {
        contentDetail: (state, action) => {
            state.contentDetail = action.payload;
        }
    },
});

export const { contentDetail } = contentDetailSlice.actions;
export default contentDetailSlice.reducer;