import React from "react";
import Modal from "react-bootstrap/Modal";
import coinIcon from "../../assets/images/coins.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const GameWinOrLoseModal = (props) => {
  const authUser = useSelector((state) => state.auth?.user);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <div className="modal-body m-auto text-center">
          <img src={coinIcon} alt="" width="200" className="mb-4" />
          {props?.gameStatus?.is_win ? (
            <h4 className="text-dark mb-2"> You Won! </h4>
          ) : (
            <h4 className="text-dark mb-2"> You Failed! </h4>
          )}
          <h5 className="text-dark">
            {" "}
            Your got{" "}
            <span style={{ fontSize: "30px" }} className="text-danger">
              {" "}
              {props?.gameStatus?.point || 0}{" "}
            </span>{" "}
            points{" "}
          </h5>
        </div>
      </Modal.Body>
      {authUser?.status !== 1 && (
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <Link
            to="/manage-profile"
            class="btn btn-danger"
            data-toggle="modal"
            data-target="#points-claimed"
            data-dismiss="modal"
          >
            {" "}
            Claim your points{" "}
          </Link>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default GameWinOrLoseModal;
