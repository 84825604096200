import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    homeSlider: []
};

const sliderSlice = createSlice({
    name: "slider",
    initialState,
    reducers: {
        homeSlider: (state, action) => {
            state.homeSlider = action.payload;
        },
    },
});

export const { homeSlider } = sliderSlice.actions;
export default sliderSlice.reducer;