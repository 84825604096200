import { handleAllError, successToast } from "../../utils/utils";
import { apiSlice } from "../api/apiSlice";
import { userLoggedIn, userLoggedOut } from "./authSlice";
import statusEnum from "../../enums/statusEnum";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/v1/login",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.code === statusEnum.SUCCESS) {
            successToast(data?.message[0]);
          }
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/v1/logout",
        method: "POST",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.code === statusEnum.SUCCESS) {
            // successToast(data?.message[0]);

            dispatch(userLoggedOut());
            dispatch(apiSlice.util.resetApiState());
          }
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    otp: builder.mutation({
      query: (data) => ({
        url: "/v1/otp-login",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.code === statusEnum.SUCCESS) {
            successToast(data?.message[0]);
            // Update state with this data
            dispatch(
              userLoggedIn({
                accessToken: data?.data?.token,
                user: data?.data,
              })
            );
            dispatch(apiSlice.util.resetApiState());
          }
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    profileUpdate: builder.mutation({
      query: (data) => ({
        url: "/v1/profile-update",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch, getState }) {
        try {
          const { data } = await queryFulfilled;
          const auth = getState().auth;
          if (data?.code === statusEnum.SUCCESS) {
            dispatch(
              userLoggedIn({
                accessToken: auth.accessToken,
                user: data?.data,
              })
            );
            localStorage.setItem(
              "auth",
              JSON.stringify({
                accessToken: auth.accessToken,
                user: data?.data,
              })
            );
            successToast(data?.message[0]);
          }
          handleAllError(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useOtpMutation,
  useLogoutMutation,
  useProfileUpdateMutation,
} = authApi;
