import React from "react";
import bgImg from "assets/images/video/bg.jpg";
import { Link, useParams } from "react-router-dom";
import {
  useAddToFavouriteMutation,
  useGetMoviesQuery,
} from "features/home/movies/moviesApi";
import {
  HERO_CONTENT_ALL,
  MOVIE_LIST,
  TRENDING_NOW_ALL,
  TV_SHOW_LIST,
  BANGLA_NATOK_LIST,
} from "service/apiEndPoints";
import { durationFormat, getCurrentCategory, handleEvent } from "utils/utils";
import { moviesApi } from "features/home/movies/moviesApi";
import { useDispatch } from "react-redux";
import CustomLoader from "ui/Loader/CustomLoader";
import DataNotFound from "ui/DataNotFound/DataNotFound";
// import StarRating from "components/StarRating/StarRating";

const ViewAll = () => {
  const { type } = useParams();
  const currentCategory = getCurrentCategory(type);
  const { data, isLoading } = useGetMoviesQuery(currentCategory);
  const [addToFavourite] = useAddToFavouriteMutation();

  const handleAddToFavourite = (content_id, uuid) => {
    addToFavourite({ content_id, endpoint: currentCategory, uuid });
  };
  const dispatch = useDispatch();

  const getPath = (currentPage = 1) => {
    switch (type) {
      case "movie":
        return `${MOVIE_LIST}?page=${currentPage}`;
      case "tv-show":
        return `${TV_SHOW_LIST}?page=${currentPage}`;

      case "hero-contents":
        return `${HERO_CONTENT_ALL}?page=${currentPage}`;

      case "trending-now":
        return `${TRENDING_NOW_ALL}?page=${currentPage}`;
      case "bangla-natok":
        return `${BANGLA_NATOK_LIST}?page=${currentPage}`;
      default:
        return "";
    }
  };

  const handleLoadMore = () => {
    const currentPage = data?.data?.paginator?.current_page + 1;

    // dispatch(
    //   moviesApi.endpoints.loadMore.initiate({
    //     endpoint: getPath(currentPage),
    //     currentPage,
    //   })
    // );


    dispatch(
      moviesApi.endpoints.loadMore.initiate({
        endpoint: getPath(currentPage),
        page: currentPage,
      })
    );
    // Update the query data
    // dispatch(
    //   moviesApi.util.updateQueryData(
    //     "getMovies",
    //     getPath(currentPage),
    //     (draft) => {
    //       const existingContent = JSON.parse(JSON.stringify(draft));
    //       console.log(existingContent)
    //       // insert new data
    //       existingContent.data = [...existingContent.data, ...data?.data?.data];
    //       existingContent.paginator = data?.data?.paginator;
    //       console.log(existingContent);
    //       return existingContent;
    //     }
    //   )
    // );
  };

  if (isLoading) return <CustomLoader />;

  return (
    <>
      <div
        className="iq-breadcrumb-one  iq-bg-over "
        style={{
          backgroundImage: `url(${bgImg})`,
        }}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav
                aria-label="breadcrumb"
                className="text-center iq-breadcrumb-two"
              >
                <h2 className="title">View All</h2>
                <ol className="breadcrumb main-bg">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      onClick={() => {
                        handleEvent("Home", "View All BreadCrumb", "Home");
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {getCurrentCategory(type, false)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <main id="main" className="site-main watchlist-contens">
        <div className="container-fluid">
          <div className="iq-main-header d-flex align-items-center justify-content-between mt-5 mt-lg-0">
            <h4 className="main-title">{getCurrentCategory(type, false)}</h4>
          </div>
          {data?.data?.data?.length > 0 ? (
            <>
              <ul className=" row list-inline  mb-0 iq-rtl-direction ">
                {data?.data?.data?.map(
                  (
                    {
                      id,
                      content_id,
                      cover_image,
                      title,
                      duration,
                      total_favourite,
                      user_favourite,
                    },
                    index
                  ) => (
                    <li
                      className="slide-item col-lg-3 col-md-4 col-6 mb-4"
                      key={index}
                    >
                      <div className="block-images position-relative  watchlist-first genres-section">
                        <div className="img-box">
                          <img
                            src={cover_image}
                            className="img-fluid"
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="block-description">
                          <h6 className="iq-title text-left">
                            <Link
                              to={`/show-detail/movie/${id}`}
                              onClick={() => {
                                handleEvent(
                                  "View All",
                                  "Clicked on Title",
                                  title
                                );
                              }}
                            >
                              {title}
                            </Link>
                          </h6>
                          <div className="movie-time d-flex align-items-center my-2">
                            <span className="text-white">
                              {durationFormat(duration)}
                            </span>
                          </div>
                          <div className="hover-buttons text-left">
                            <Link
                              to={`/show-detail/movie/${id}`}
                              role="button"
                              className="btn btn-hover"
                              onClick={() => {
                                handleEvent(
                                  "View All",
                                  "Clicked on Play Now",
                                  title
                                );
                              }}
                            >
                              <i
                                className="fa fa-play mr-1"
                                aria-hidden="true"
                              ></i>
                              Play Now
                            </Link>
                          </div>
                        </div>
                        <div className="block-social-info">
                          <ul className="list-inline p-0 m-0 music-play-lists">
                            <li
                            // onClick={() => handleCountValue(id, total_favourite)}
                            >
                              <span
                                style={{
                                  background: user_favourite
                                    ? "#e50914"
                                    : "#fff",
                                }}
                                className={` count_bg`}
                                onClick={() => {
                                  handleEvent(
                                    "View All",
                                    "Clicked on Favourite Icon Button",
                                    title
                                  );
                                  handleAddToFavourite(id, content_id);
                                }}
                              >
                                <i
                                  className={`ri-heart-fill`}
                                  style={{
                                    color: user_favourite ? "#fff" : "#e50914",
                                  }}
                                ></i>
                              </span>
                              {total_favourite ? (
                                <span
                                  className={`count-box `}
                                  // ref={countRef}
                                >
                                  {total_favourite}+
                                </span>
                              ) : null}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
              {data?.data?.paginator?.current_page <
                data?.data?.paginator?.total_pages && (
                <button
                  onClick={() => handleLoadMore()}
                  className="btn btn-hover hide-me"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample3"
                  aria-expanded="false"
                  aria-controls="collapseExample3"
                >
                  <span className="genres-btn">LOAD MORE</span>
                </button>
              )}
            </>
          ) : (
            <DataNotFound />
          )}
        </div>
      </main>
    </>
  );
};

export default ViewAll;
