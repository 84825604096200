import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useGetSiteDataQuery } from "features/siteData/siteDataAPI";
import CustomLoader from "ui/Loader/CustomLoader";
import Breadcrumbs from "ui/Breadcrumb/Breadcrumbs";

const TermsOfUse = () => {
  const { data, isLoading } = useGetSiteDataQuery();

  // What to do render
  let content = null;
  if (isLoading) return <CustomLoader />;
  else if (data?.data?.terms_condition)
    content = (
      <div dangerouslySetInnerHTML={{ __html: data?.data?.terms_condition }} />
    );
  return (
    <>
      <Breadcrumbs title="Terms Of Use" />
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-privacy-policy">
                <div className="mb-3">{content}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default TermsOfUse;
