import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isReactModal: false,
};

const modalVideoPlayerSlice = createSlice({
    name: "modalVideoPlayer",
    initialState,
    reducers: {
        isReactModal: (state) => {
            state.isReactModal = !state.isReactModal;
        }
    },
});

export const { isReactModal } = modalVideoPlayerSlice.actions;
export default modalVideoPlayerSlice.reducer;