import React from "react";
import { ThreeDots } from "react-loader-spinner";
export const ThreeDotLoader = ({
  width = 50,
  height = 30,
  color = "#4fa94d",
}) => {
  return (
    <ThreeDots
      height={height}
      width={width}
      radius="9"
      color={color}
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName="three-dots-loading"
      visible={true}
    />
  );
};
