import React from "react";
import Modal from "react-bootstrap/Modal";
// import VideoJs from "../VideoJS";
import IframePlayer from "../IframePlayer/IframePlayer";

const TvSeriesModal = ({
  stream_url,
  thumbnail,
  stream_id,
  open,
  setOpen,
  thumbnail_sprite = "",
  category_id = 2,
  duration = 0,
}) => {
  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
    >
      <Modal.Body className="p-0">
        <div
          className="modal-video-close-btn"
          onClick={() => setOpen(false)}
          style={{ cursor: "pointer" }}
        ></div>
        <IframePlayer stream_url={stream_url} />
        {/* <VideoJs
          stream_url={stream_url}
          thumbnail={thumbnail}
          stream_id={stream_id}
          thumbnail_sprite={thumbnail_sprite}
          categoryId={category_id}
          duration={duration}
          autoPlay={true}
        /> */}
      </Modal.Body>
    </Modal>
  );
};

export default TvSeriesModal;