import React from "react";

const ImageBox = ({ img }) => {
  return (
    <div className="img-box">
      <img src={img} className="img-fluid" alt="" />
    </div>
  );
};

export default ImageBox;
