import React from "react";
import { useParams } from "react-router-dom";
import { useContentDetailMutation } from "features/contentDetail/contentDetailAPI";
import { useEffect } from "react";
import bannerImg from "assets/images/banner-1.jpeg";
import SessionalVideo from "components/ShowDetail/SessionalVideo";
import Starring from "components/ShowDetail/Starring";
import MovieDetails from "../movieDetails/movieDetails";
import Banner from "components/ShowDetail/Banner";
import CustomLoader from "ui/Loader/CustomLoader";
import DataNotFound from "ui/DataNotFound/DataNotFound";

const ShowDetail = () => {
  const { id } = useParams();
  const [contentDetail, { data, isLoading }] = useContentDetailMutation();
  useEffect(() => {
    contentDetail({ content_id: id });
    // eslint-disable-next-line
  }, [id]);

  // What to do render
  let content = null;
  if (isLoading) return <CustomLoader />;
  else if (data?.data) {
    content = (
      <>
        {data?.data?.category?.id === 2 ? (
          <div className="main-content">
            <div className="show-movie mt-4">
              <div className="container-fluid">
                <Banner data={data?.data} />
                <SessionalVideo
                  bannerImg={bannerImg}
                  sessions={data?.data?.seasons}
                />
                <Starring actor={data?.data?.actor} />
              </div>
            </div>
          </div>
        ) : (
          <MovieDetails data={data?.data} />
        )}
      </>
    );
  } else {
    content = <DataNotFound />;
  }

  return content;
};

export default ShowDetail;
