import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const NavbarItems = () => {
  // If routes change, add navbar classname = navbar-collapse collapse only for mobile view
  const pathName = useLocation().pathname;

  useEffect(() => {
    const navbar = document.querySelector(".navbar-collapse");
    if (navbar) {
      navbar.classList.remove("show");
    }
  }, [pathName]);

  useEffect(() => {
    const closeNavbar = (event) => {
      const navbar = document.querySelector(".navbar-collapse");
      if (navbar && !navbar.contains(event.target)) {
        navbar.classList.remove("show");
      }
    };

    document.addEventListener("mousedown", closeNavbar);

    return () => document.removeEventListener("mousedown", closeNavbar);
  }, []);

  return (
    <Navbar.Collapse className="">
      <div className="menu-main-menu-container">
        <Nav as="ul" id="top-menu" className="ml-auto">
          <li className="menu-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li className="menu-item">
            <Link to="/movies">Movie Station</Link>
          </li>
          <li className="menu-item">
            <Link to="/tv-series">Tv Series</Link>
          </li>
          {/* <li className="menu-item">
            <Link to={user?.status === 3 ? "#" : "/music-video"}>
              Music Videos
            </Link>
          </li> */}
          <li className="menu-item">
            <Link to="/bangla-natok">Bangla Natok</Link>
          </li>

          <li className="menu-item">
            <Link to="/games">Games Station</Link>
          </li>
        </Nav>
      </div>
    </Navbar.Collapse>
  );
};

export default NavbarItems;
